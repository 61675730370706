import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { Header } from './components/Header';
import { AdminDashboard } from './components/admin/AdminDashboard';
import { BusinessDashboard } from './components/business/BusinessDashboard';
import { CategoryFilter } from './components/CategoryFilter';
import { PlaceCard } from './components/PlaceCard';
import { QuizFooter } from './components/QuizFooter';
import { RoleSwitcher } from './components/RoleSwitcher';
import { FavoritesOverlay } from './components/FavoritesOverlay';
import { BookmarksOverlay } from './components/BookmarksOverlay';
import { SpecialOffersOverlay } from './components/SpecialOffersOverlay';
import { UserReviewsOverlay } from './components/UserReviewsOverlay';
import { VisitedPlacesOverlay } from './components/VisitedPlacesOverlay';
import { BonusPointsOverlay } from './components/BonusPointsOverlay';
import { InfoSheet } from './components/InfoSheet';
import { VenueDetails } from './components/VenueDetails';
import { useAppState } from './lib/state';
import { PLACES } from './data/places';
import { WelcomeSplashOverlay } from './components/WelcomeSplashOverlay';
import { BonusPointsNotification } from './components/BonusPointsNotification';
import { parseReferralCode, storeReferralData } from './lib/referral';

export function App() {
  const {
    isSignedIn,
    userProfile,
    userPoints,
    quizStats,
    updateQuizStats,
    signIn,
    lastBonusPoints
  } = useAppState();

  const [showWelcomeSplash, setShowWelcomeSplash] = useState(() => {
    const hasVisited = localStorage.getItem('hasVisitedBefore');
    return !hasVisited;
  });

  const [showSignUp, setShowSignUp] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [showFavorites, setShowFavorites] = useState(false);
  const [showBookmarks, setShowBookmarks] = useState(false);
  const [showSpecialOffers, setShowSpecialOffers] = useState(false);
  const [showReviews, setShowReviews] = useState(false);
  const [showVisited, setShowVisited] = useState(false);
  const [showBonusPoints, setShowBonusPoints] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<string | null>(null);

  // Check for referral code in URL
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ref = params.get('ref');
    
    if (ref) {
      const referralData = parseReferralCode(ref);
      if (referralData) {
        // Store referral data for when they sign up
        storeReferralData(referralData);
        
        // Clean URL
        window.history.replaceState({}, '', window.location.pathname);
      }
    }
  }, []);

  // Mark as visited when splash is shown
  useEffect(() => {
    if (showWelcomeSplash) {
      localStorage.setItem('hasVisitedBefore', 'true');
    }
  }, [showWelcomeSplash]);

  // Auto sign in for testing
  useEffect(() => {
    if (!isSignedIn) {
      signIn({
        name: 'Test User',
        email: 'test@example.com',
        password: 'password123',
        role: 'user'
      });
    }
  }, [isSignedIn, signIn]);

  const handlePlaceClick = (id: string) => {
    setSelectedPlace(id);
  };

  const handleGetStarted = () => {
    setShowWelcomeSplash(false);
    setShowSignUp(true);
  };

  // Filter places based on selected category
  const filteredPlaces = selectedCategory
    ? PLACES.filter(place => 
        place.category === selectedCategory || 
        place.categories?.includes(selectedCategory)
      )
    : PLACES;

  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen w-full bg-gray-100">
        <Header 
          isSignedIn={isSignedIn}
          userProfile={userProfile}
          userPoints={userPoints}
          onSignUp={() => setShowSignUp(true)}
          onSignIn={() => {}}
          onSignOut={() => {}}
          onShowProfile={() => {}}
          onShowFavorites={() => setShowFavorites(true)}
          onShowBookmarks={() => setShowBookmarks(true)}
          onShowReviews={() => setShowReviews(true)}
          onShowVisited={() => setShowVisited(true)}
          onShowBonusPoints={() => setShowBonusPoints(true)}
          onShowSpecialOffers={() => setShowSpecialOffers(true)}
          onShowInfo={() => setShowInfo(true)}
          heroButtonText={isSignedIn ? 'specialOffers' : 'joinToday'}
          quizStats={quizStats}
        />

        {/* Admin/Business Navigation */}
        {isSignedIn && (userProfile?.role === 'admin' || userProfile?.role === 'venue_owner') && (
          <div className="bg-indigo-900 text-white py-2">
            <div className="max-w-7xl mx-auto px-4 flex gap-4">
              {userProfile.role === 'admin' && (
                <Link 
                  to="/admin"
                  className="px-4 py-2 rounded-lg hover:bg-white/10 transition-colors"
                >
                  Admin Dashboard
                </Link>
              )}
              {userProfile.role === 'venue_owner' && (
                <Link 
                  to="/business"
                  className="px-4 py-2 rounded-lg hover:bg-white/10 transition-colors"
                >
                  Business Dashboard
                </Link>
              )}
            </div>
          </div>
        )}

        <div className="flex-1">
          <Routes>
            {/* Main Content */}
            <Route path="/" element={
              <main className="max-w-7xl mx-auto pb-12">
                <CategoryFilter 
                  selected={selectedCategory}
                  onSelect={setSelectedCategory}
                  isSignedIn={isSignedIn}
                  onSignUp={() => setShowSignUp(true)}
                />
                <div className="px-4 grid gap-6 mt-6 sm:grid-cols-2 lg:grid-cols-3">
                  {filteredPlaces.map(place => (
                    <PlaceCard
                      key={place.id}
                      place={place}
                      onShowDetails={handlePlaceClick}
                      isSignedIn={isSignedIn}
                      onSignUp={() => setShowSignUp(true)}
                    />
                  ))}
                </div>
              </main>
            } />

            {/* Admin Dashboard */}
            <Route 
              path="/admin" 
              element={
                userProfile?.role === 'admin' ? (
                  <AdminDashboard />
                ) : (
                  <div className="p-8 text-center">
                    Not authorized to view this page
                  </div>
                )
              } 
            />

            {/* Business Dashboard */}
            <Route 
              path="/business" 
              element={
                userProfile?.role === 'venue_owner' ? (
                  <BusinessDashboard placeId="tour-1" />
                ) : (
                  <div className="p-8 text-center">
                    Not authorized to view this page
                  </div>
                )
              } 
            />
          </Routes>
        </div>

        <QuizFooter 
          isSignedIn={isSignedIn}
          onSignUp={() => setShowSignUp(true)}
          quizStats={quizStats}
          onQuizComplete={updateQuizStats}
        />

        {/* Role Switcher for Testing */}
        {isSignedIn && <RoleSwitcher onShowSplash={() => setShowWelcomeSplash(true)} />}

        {/* Welcome Splash */}
        {showWelcomeSplash && (
          <WelcomeSplashOverlay
            onClose={() => setShowWelcomeSplash(false)}
            onGetStarted={handleGetStarted}
          />
        )}

        {/* Overlays */}
        {showFavorites && (
          <FavoritesOverlay
            onClose={() => setShowFavorites(false)}
            onPlaceClick={handlePlaceClick}
          />
        )}

        {showBookmarks && (
          <BookmarksOverlay
            onClose={() => setShowBookmarks(false)}
            onPlaceClick={handlePlaceClick}
          />
        )}

        {showSpecialOffers && (
          <SpecialOffersOverlay
            onClose={() => setShowSpecialOffers(false)}
            onPlaceClick={handlePlaceClick}
          />
        )}

        {showReviews && (
          <UserReviewsOverlay
            onClose={() => setShowReviews(false)}
            onPlaceClick={handlePlaceClick}
          />
        )}

        {showVisited && (
          <VisitedPlacesOverlay
            onClose={() => setShowVisited(false)}
            onPlaceClick={handlePlaceClick}
          />
        )}

        {showBonusPoints && (
          <BonusPointsOverlay
            onClose={() => setShowBonusPoints(false)}
            points={userPoints}
          />
        )}

        {showInfo && (
          <InfoSheet
            onClose={() => setShowInfo(false)}
            onSignUp={() => setShowSignUp(true)}
          />
        )}

        {selectedPlace && (
          <VenueDetails
            place={PLACES.find(p => p.id === selectedPlace)!}
            onClose={() => setSelectedPlace(null)}
          />
        )}

        {/* Bonus Points Notification */}
        {lastBonusPoints && (
          <BonusPointsNotification
            points={lastBonusPoints.points}
            action={lastBonusPoints.action}
            onClose={() => {}}
          />
        )}
      </div>
    </BrowserRouter>
  );
}