import React, { useState } from 'react';
import { UserCog, Sparkles, RotateCcw } from 'lucide-react';
import { useAppState } from '../lib/state';
import { useNavigate } from 'react-router-dom';

interface RoleSwitcherProps {
  onShowSplash?: () => void;
}

export function RoleSwitcher({ onShowSplash }: RoleSwitcherProps) {
  const { userProfile, switchRole, signOut } = useAppState();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleRoleSwitch = (role: 'user' | 'admin' | 'venue_owner') => {
    switchRole(role);
    setIsOpen(false);
    
    // Navigate based on new role
    switch (role) {
      case 'admin':
        navigate('/admin');
        break;
      case 'venue_owner':
        navigate('/business');
        break;
      default:
        navigate('/');
    }
  };

  const handleShowSplash = () => {
    if (onShowSplash) {
      onShowSplash();
      setIsOpen(false);
    }
  };

  const handleResetState = async () => {
    await signOut();
    setIsOpen(false);
    navigate('/');
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-50"
        title="Test Role"
      >
        <UserCog className="w-5 h-5 text-indigo-600" />
      </button>

      {isOpen && (
        <div className="absolute bottom-full right-0 mb-2 min-w-[140px] bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-2 space-y-1">
            <button
              onClick={() => handleRoleSwitch('user')}
              className={`w-full px-3 py-1.5 text-sm text-left rounded transition-colors
                ${userProfile?.role === 'user' 
                  ? 'bg-indigo-600 text-white' 
                  : 'hover:bg-gray-100 text-gray-700'}`}
            >
              Regular User
            </button>
            
            <button
              onClick={() => handleRoleSwitch('admin')}
              className={`w-full px-3 py-1.5 text-sm text-left rounded transition-colors
                ${userProfile?.role === 'admin' 
                  ? 'bg-indigo-600 text-white' 
                  : 'hover:bg-gray-100 text-gray-700'}`}
            >
              Admin
            </button>
            
            <button
              onClick={() => handleRoleSwitch('venue_owner')}
              className={`w-full px-3 py-1.5 text-sm text-left rounded transition-colors
                ${userProfile?.role === 'venue_owner' 
                  ? 'bg-indigo-600 text-white' 
                  : 'hover:bg-gray-100 text-gray-700'}`}
            >
              Venue Owner
            </button>

            <div className="border-t border-gray-200 my-1" />

            <button
              onClick={handleShowSplash}
              className="w-full px-3 py-1.5 text-sm text-left rounded transition-colors hover:bg-gray-100 text-gray-700 flex items-center gap-2"
            >
              <Sparkles className="w-4 h-4" />
              Show Splash Screen
            </button>

            <button
              onClick={handleResetState}
              className="w-full px-3 py-1.5 text-sm text-left rounded transition-colors hover:bg-gray-100 text-red-600 flex items-center gap-2"
            >
              <RotateCcw className="w-4 h-4" />
              Reset State
            </button>
          </div>
        </div>
      )}
    </div>
  );
}