import React, { useState } from 'react';
import { X, User, Mail, Lock, Globe, Bell, Home } from 'lucide-react';
import { OverlayWrapper } from './OverlayWrapper';
import { signUp } from '../lib/auth';
import { useAppState } from '../lib/state';
import { COUNTRIES } from '../data/countries';
import { getReferralData, clearReferralData } from '../lib/referral';

interface SignUpFormProps {
  onClose: () => void;
  onSubmit: (data: { name: string; email: string; password: string }) => void;
  onShowInfo: () => void;
}

export function SignUpForm({ onClose, onSubmit, onShowInfo }: SignUpFormProps) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    visitingFrom: '',
    isLocal: false,
    notificationsEnabled: true,
    agreeToTerms: false,
    agreeToPrivacy: false
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showPolicies, setShowPolicies] = useState(false);

  // Get signIn action from Zustand store
  const { signIn: signInStore, awardReferralPoints } = useAppState();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.agreeToTerms || !formData.agreeToPrivacy) return;

    setIsLoading(true);
    setError(null);
    
    try {
      // Check for referral data
      const referralData = getReferralData();
      
      const { error: signUpError } = await signUp(
        formData.email,
        formData.password,
        formData.name
      );

      if (signUpError) {
        throw signUpError;
      }

      // If signup successful and there was a referral, award points
      if (referralData) {
        awardReferralPoints(referralData.userId, referralData.shareId);
        clearReferralData();
      }

      onSubmit({
        name: formData.name,
        email: formData.email,
        password: formData.password
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to sign up');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <OverlayWrapper>
      <div className="fixed inset-4 bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl shadow-xl overflow-hidden">
        <div className="h-full flex flex-col">
          {/* Header */}
          <div className="flex-none p-6 border-b border-white/10">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold text-white">Join mycam.city</h2>
              <button 
                onClick={onClose}
                className="p-2 text-white hover:bg-white/10 rounded-lg"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            {/* Why Join Button */}
            <button
              onClick={() => {
                onClose();
                onShowInfo();
              }}
              className="w-full mt-4 px-4 py-3 bg-white/10 text-white rounded-lg hover:bg-white/20 
                transition-colors flex items-center justify-center gap-2"
            >
              <User className="w-5 h-5" />
              <span>Why Join mycam.city?</span>
            </button>
          </div>

          {/* Form */}
          <form onSubmit={handleSubmit} className="flex-1 overflow-y-auto">
            <div className="p-6 space-y-6">
              {!showPolicies ? (
                <>
                  {/* Basic Info */}
                  <div className="space-y-4">
                    {/* Name */}
                    <div>
                      <label className="flex items-center gap-2 text-white mb-2">
                        <User className="w-5 h-5" />
                        <span>Name</span>
                      </label>
                      <input
                        type="text"
                        required
                        value={formData.name}
                        onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                        className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg text-white 
                          placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/25"
                        placeholder="Enter your name"
                      />
                    </div>

                    {/* Email */}
                    <div>
                      <label className="flex items-center gap-2 text-white mb-2">
                        <Mail className="w-5 h-5" />
                        <span>Email</span>
                      </label>
                      <input
                        type="email"
                        required
                        value={formData.email}
                        onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
                        className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg text-white 
                          placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/25"
                        placeholder="Enter your email"
                      />
                    </div>

                    {/* Password */}
                    <div>
                      <label className="flex items-center gap-2 text-white mb-2">
                        <Lock className="w-5 h-5" />
                        <span>Password</span>
                      </label>
                      <input
                        type="password"
                        required
                        minLength={6}
                        value={formData.password}
                        onChange={e => setFormData(prev => ({ ...prev, password: e.target.value }))}
                        className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg text-white 
                          placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/25"
                        placeholder="Choose a password"
                      />
                    </div>

                    {/* Visiting From */}
                    <div className="space-y-3">
                      <label className="flex items-center gap-2 text-white mb-2">
                        <Globe className="w-5 h-5" />
                        <span>Visiting From</span>
                      </label>
                      <select
                        value={formData.visitingFrom}
                        onChange={e => setFormData(prev => ({ ...prev, visitingFrom: e.target.value }))}
                        disabled={formData.isLocal}
                        className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg text-white 
                          focus:outline-none focus:ring-2 focus:ring-white/25 appearance-none disabled:opacity-50"
                        style={{ textIndent: '8px' }}
                      >
                        <option value="" className="bg-gray-900">Select a country</option>
                        {COUNTRIES.map(country => (
                          <option 
                            key={country.code} 
                            value={country.code}
                            className="bg-gray-900"
                          >
                            {country.flag} {country.name}
                          </option>
                        ))}
                      </select>

                      {/* Local Checkbox */}
                      <label className="flex items-center gap-3 text-white cursor-pointer">
                        <input
                          type="checkbox"
                          checked={formData.isLocal}
                          onChange={e => setFormData(prev => ({ 
                            ...prev, 
                            isLocal: e.target.checked,
                            visitingFrom: e.target.checked ? '' : prev.visitingFrom
                          }))}
                          className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                        />
                        <div className="flex items-center gap-2">
                          <Home className="w-4 h-4" />
                          <span className="text-sm">I'm a local</span>
                        </div>
                      </label>
                    </div>

                    {/* Notifications */}
                    <div>
                      <label className="flex items-center gap-2 text-white mb-2">
                        <Bell className="w-5 h-5" />
                        <span>Notifications</span>
                      </label>
                      <label className="flex items-center gap-3 text-white cursor-pointer">
                        <input
                          type="checkbox"
                          checked={formData.notificationsEnabled}
                          onChange={e => setFormData(prev => ({ 
                            ...prev, 
                            notificationsEnabled: e.target.checked 
                          }))}
                          className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                        />
                        <span className="text-sm">Enable notifications about special offers and events</span>
                      </label>
                    </div>

                    <button
                      type="button"
                      onClick={() => setShowPolicies(true)}
                      className="w-full px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-500 
                        transition-colors font-medium mt-4"
                    >
                      Continue
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {/* Policies Section */}
                  <div className="space-y-4">
                    <label className="flex items-start gap-2 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={formData.agreeToTerms}
                        onChange={e => setFormData(prev => ({ ...prev, agreeToTerms: e.target.checked }))}
                        className="mt-1"
                      />
                      <span className="text-sm text-white/90">
                        I agree to the <a href="/policy.html#terms" target="_blank" className="underline">Terms of Service</a>
                      </span>
                    </label>

                    <label className="flex items-start gap-2 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={formData.agreeToPrivacy}
                        onChange={e => setFormData(prev => ({ ...prev, agreeToPrivacy: e.target.checked }))}
                        className="mt-1"
                      />
                      <span className="text-sm text-white/90">
                        I agree to the <a href="/policy.html#privacy" target="_blank" className="underline">Privacy Policy</a>
                      </span>
                    </label>

                    {error && (
                      <div className="px-3 py-2 bg-red-500/20 border border-red-500/30 rounded-lg">
                        <p className="text-sm text-white">{error}</p>
                      </div>
                    )}

                    <div className="flex gap-3 pt-4">
                      <button
                        type="button"
                        onClick={() => setShowPolicies(false)}
                        className="flex-1 px-6 py-3 bg-white/10 text-white rounded-lg hover:bg-white/20 
                          transition-colors font-medium"
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        disabled={isLoading || !formData.agreeToTerms || !formData.agreeToPrivacy}
                        className="flex-1 px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-500 
                          transition-colors font-medium disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        {isLoading ? 'Signing up...' : 'Sign Up'}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </OverlayWrapper>
  );
}