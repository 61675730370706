import React, { useState } from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { X, User, Mail, Bell, Globe, Home } from 'lucide-react';
import { useAppState } from '../lib/state';
import { COUNTRIES } from '../data/countries';

interface EditProfileOverlayProps {
  onClose: () => void;
}

export function EditProfileOverlay({ onClose }: EditProfileOverlayProps) {
  const { userProfile, updateUserProfile } = useAppState();

  const [formData, setFormData] = useState({
    name: userProfile?.name || '',
    email: userProfile?.email || '',
    notificationsEnabled: userProfile?.notificationsEnabled ?? true,
    visitingFrom: userProfile?.visitingFrom || '',
    isLocal: userProfile?.isLocal ?? false
  });

  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);

    try {
      await updateUserProfile(formData);
      onClose();
    } catch (error) {
      console.error('Failed to update profile:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <OverlayWrapper>
      <div className="fixed inset-4 bg-gradient-to-br from-indigo-900 via-purple-900 to-[#1A1F2B] rounded-xl shadow-xl overflow-hidden">
        <div className="h-full flex flex-col">
          {/* Header */}
          <div className="flex justify-between items-center p-6 border-b border-white/10">
            <h2 className="text-xl font-bold text-white">Edit Profile</h2>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-white/10 rounded-lg text-white"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {/* Form */}
          <form onSubmit={handleSubmit} className="flex-1 overflow-y-auto p-6">
            <div className="space-y-6">
              {/* Name */}
              <div>
                <label className="flex items-center gap-2 text-white mb-2">
                  <User className="w-5 h-5" />
                  <span>Name</span>
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg text-white 
                    placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/25"
                  placeholder="Enter your name"
                  required
                />
              </div>

              {/* Email */}
              <div>
                <label className="flex items-center gap-2 text-white mb-2">
                  <Mail className="w-5 h-5" />
                  <span>Email</span>
                </label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
                  className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg text-white 
                    placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/25"
                  placeholder="Enter your email"
                  required
                />
              </div>

              {/* Visiting From */}
              <div className="space-y-3">
                <label className="flex items-center gap-2 text-white mb-2">
                  <Globe className="w-5 h-5" />
                  <span>Visiting From</span>
                </label>
                <select
                  value={formData.visitingFrom}
                  onChange={e => setFormData(prev => ({ ...prev, visitingFrom: e.target.value }))}
                  disabled={formData.isLocal}
                  className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg text-white 
                    focus:outline-none focus:ring-2 focus:ring-white/25 appearance-none disabled:opacity-50"
                  style={{ textIndent: '8px' }}
                >
                  <option value="" className="bg-gray-900">Select a country</option>
                  {COUNTRIES.map(country => (
                    <option 
                      key={country.code} 
                      value={country.code}
                      className="bg-gray-900"
                    >
                      {country.flag} {country.name}
                    </option>
                  ))}
                </select>

                {/* Local Checkbox */}
                <label className="flex items-center gap-3 text-white cursor-pointer">
                  <input
                    type="checkbox"
                    checked={formData.isLocal}
                    onChange={e => setFormData(prev => ({ 
                      ...prev, 
                      isLocal: e.target.checked,
                      visitingFrom: e.target.checked ? '' : prev.visitingFrom
                    }))}
                    className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                  />
                  <div className="flex items-center gap-2">
                    <Home className="w-4 h-4" />
                    <span className="text-sm">I'm a local</span>
                  </div>
                </label>
              </div>

              {/* Notifications */}
              <div>
                <label className="flex items-center gap-2 text-white mb-2">
                  <Bell className="w-5 h-5" />
                  <span>Notifications</span>
                </label>
                <label className="flex items-center gap-3 text-white cursor-pointer">
                  <input
                    type="checkbox"
                    checked={formData.notificationsEnabled}
                    onChange={e => setFormData(prev => ({ 
                      ...prev, 
                      notificationsEnabled: e.target.checked 
                    }))}
                    className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                  />
                  <span className="text-sm">Enable notifications about special offers and events</span>
                </label>
              </div>

              {/* Save Button */}
              <button
                type="submit"
                disabled={isSaving}
                className="w-full mt-6 px-6 py-3 bg-indigo-600 hover:bg-indigo-500 text-white rounded-lg 
                  transition-colors flex items-center justify-center gap-2 disabled:opacity-50 
                  disabled:cursor-not-allowed"
              >
                {isSaving ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    Saving...
                  </>
                ) : (
                  'Save Changes'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </OverlayWrapper>
  );
}