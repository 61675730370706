import React, { useState, useEffect } from 'react';
import { Brain, Sparkles } from 'lucide-react';
import { SignUpForm } from './SignUpForm';
import { QuizOverlay } from './QuizOverlay';
import { OtherSydeOverlay } from './OtherSydeOverlay';
import type { QuizQuestion, QuizStats } from '../types';
import { QUIZ_QUESTIONS } from '../data/quizQuestions';

interface QuizFooterProps {
  isSignedIn: boolean;
  onSignUp: (data: { name: string; email: string; password: string }) => void;
  quizStats: QuizStats;
  onQuizComplete: (stats: QuizStats) => void;
}

export function QuizFooter({ isSignedIn, onSignUp, quizStats, onQuizComplete }: QuizFooterProps) {
  const [currentQuestion, setCurrentQuestion] = useState<QuizQuestion>();
  const [showQuizOverlay, setShowQuizOverlay] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [showOtherSyde, setShowOtherSyde] = useState(false);

  useEffect(() => {
    selectNewQuestion();
  }, []);

  const selectNewQuestion = () => {
    // Filter out questions that have been answered (correctly or incorrectly)
    const answeredQuestionIds = new Set([
      ...quizStats.correctlyAnsweredIds,
      ...quizStats.incorrectlyAnsweredIds
    ]);

    const availableQuestions = QUIZ_QUESTIONS.filter(q => !answeredQuestionIds.has(q.id));
    
    if (availableQuestions.length === 0) {
      // If all questions have been answered, reset and pick from all questions
      const randomIndex = Math.floor(Math.random() * QUIZ_QUESTIONS.length);
      setCurrentQuestion(QUIZ_QUESTIONS[randomIndex]);
      return;
    }

    const randomIndex = Math.floor(Math.random() * availableQuestions.length);
    setCurrentQuestion(availableQuestions[randomIndex]);
  };

  const handleQuestionClick = () => {
    if (!isSignedIn) {
      setShowSignUpForm(true);
      return;
    }
    setShowQuizOverlay(true);
  };

  const handleAnswerSubmit = (correct: boolean) => {
    if (!currentQuestion) return;

    // Update stats
    const newStats = {
      questionsAnswered: quizStats.questionsAnswered + 1,
      correctAnswers: quizStats.correctAnswers + (correct ? 1 : 0),
      correctlyAnsweredIds: [
        ...quizStats.correctlyAnsweredIds,
        ...(correct ? [currentQuestion.id] : [])
      ],
      incorrectlyAnsweredIds: [
        ...quizStats.incorrectlyAnsweredIds,
        ...(correct ? [] : [currentQuestion.id])
      ]
    };

    onQuizComplete(newStats);
    setShowQuizOverlay(false);
    selectNewQuestion(); // Load next question after answer
  };

  if (!currentQuestion) return null;

  const percentageCorrect = quizStats.questionsAnswered > 0
    ? Math.round((quizStats.correctAnswers / quizStats.questionsAnswered) * 100)
    : 0;

  return (
    <>
      <footer className="bg-gray-900 text-white py-4 md:py-6 mt-6 md:mt-12">
        <div className="max-w-7xl mx-auto px-4">
          {/* Quiz Score */}
          {quizStats.questionsAnswered > 0 && (
            <div className="flex items-center gap-2 text-gray-400 mb-4">
              <Brain className="w-4 h-4" />
              <span>Quiz Score: {percentageCorrect}% ({quizStats.correctAnswers}/{quizStats.questionsAnswered})</span>
            </div>
          )}

          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-3 md:gap-6">
            <div 
              onClick={handleQuestionClick}
              className="flex items-center gap-3 md:gap-6 cursor-pointer group quiz-section"
            >
              <div className="w-16 h-16 md:w-24 md:h-24 rounded-lg overflow-hidden flex-shrink-0">
                <img 
                  src={currentQuestion.imageUrl} 
                  alt="Historical figure"
                  className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                />
              </div>
              <div className="flex-1">
                <h3 className="text-base md:text-lg font-medium mb-1 md:mb-2 group-hover:text-indigo-400 transition-colors">
                  Cambridge Quiz
                </h3>
                <p className="text-sm md:text-base text-gray-300 group-hover:text-white transition-colors">
                  {currentQuestion.question}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Social Media Footer */}
        <div className="border-t border-gray-800 mt-4 pt-4">
          <div className="flex flex-col items-center gap-4">
            <div className="flex justify-center items-center gap-6">
              <div className="w-8 h-8 text-2xl flex items-center justify-center" aria-label="X (Twitter)">𝕏</div>
              <a 
                href="https://www.reddit.com/r/cambridge/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="w-8 h-8 text-2xl flex items-center justify-center"
                aria-label="Reddit"
              >
                <span style={{ fontFamily: 'Arial' }}>ℝ</span>
              </a>
              <div className="w-8 h-8 text-2xl flex items-center justify-center" aria-label="TikTok">♪</div>
              <div className="w-8 h-8 text-2xl flex items-center justify-center" aria-label="Instagram">ⓘ</div>
            </div>
            <div className="text-center">
              <p className="text-sm text-gray-500">
                This app is brought to you by
              </p>
              <button 
                onClick={() => setShowOtherSyde(true)}
                className="inline-flex items-center gap-2 text-lg font-medium text-indigo-400 hover:text-indigo-300 transition-colors mt-1"
              >
                <Sparkles className="w-5 h-5" />
                <span>OtherSyde</span>
              </button>
            </div>
          </div>
        </div>
      </footer>

      {/* Quiz Overlay */}
      {showQuizOverlay && currentQuestion && (
        <QuizOverlay
          question={currentQuestion}
          onClose={() => setShowQuizOverlay(false)}
          onAnswer={handleAnswerSubmit}
          stats={quizStats}
          isSignedIn={isSignedIn}
        />
      )}

      {/* Sign Up Form */}
      {showSignUpForm && (
        <SignUpForm
          onClose={() => setShowSignUpForm(false)}
          onSubmit={onSignUp}
          onShowInfo={() => {}}
        />
      )}

      {/* OtherSyde Overlay */}
      {showOtherSyde && (
        <OtherSydeOverlay
          onClose={() => setShowOtherSyde(false)}
        />
      )}
    </>
  );
}