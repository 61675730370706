import { createClient } from '@supabase/supabase-js';
import type { UserProfile } from '../types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseKey);

// Demo user profile
export const DEMO_USER: UserProfile = {
  id: 'demo-user',
  name: 'Cambridge Explorer',
  email: 'demo@mycam.city',
  role: 'user',
  placeInteractions: {},
  quiz_stats: {
    questionsAnswered: 0,
    correctAnswers: 0,
    correctlyAnsweredIds: [],
    incorrectlyAnsweredIds: []
  }
};

export async function signIn(email: string, password: string) {
  try {
    // In production/demo mode, use demo user
    if (import.meta.env.PROD) {
      return {
        user: { id: DEMO_USER.id, email: DEMO_USER.email },
        profile: DEMO_USER,
        error: null
      };
    }

    // In development mode, create a mock user profile
    return {
      user: { id: 'dev-user', email },
      profile: {
        id: 'dev-user',
        name: email.split('@')[0],
        email,
        role: 'user',
        placeInteractions: {},
        quiz_stats: {
          questionsAnswered: 0,
          correctAnswers: 0,
          correctlyAnsweredIds: [],
          incorrectlyAnsweredIds: []
        }
      },
      error: null
    };
  } catch (error) {
    console.error('Error signing in:', error);
    return { 
      user: null, 
      profile: null,
      error: error instanceof Error ? error : new Error('Failed to sign in') 
    };
  }
}

export async function signOut() {
  try {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    return { error: null };
  } catch (error) {
    console.error('Error signing out:', error);
    return { error: error instanceof Error ? error : new Error('Failed to sign out') };
  }
}

export async function signUp(email: string, password: string, name: string) {
  try {
    // In production/demo mode, use demo user
    if (import.meta.env.PROD) {
      return {
        user: { id: DEMO_USER.id, email: DEMO_USER.email },
        profile: DEMO_USER,
        error: null,
        confirmationEmailSent: false
      };
    }

    // In development mode, create a mock user profile
    return {
      user: { id: 'dev-user', email },
      profile: {
        id: 'dev-user',
        name,
        email,
        role: 'user',
        placeInteractions: {},
        quiz_stats: {
          questionsAnswered: 0,
          correctAnswers: 0,
          correctlyAnsweredIds: [],
          incorrectlyAnsweredIds: []
        }
      },
      error: null,
      confirmationEmailSent: false
    };
  } catch (error) {
    console.error('Error signing up:', error);
    return {
      user: null,
      profile: null,
      error: error instanceof Error ? error : new Error('Failed to sign up'),
      confirmationEmailSent: false
    };
  }
}

export async function getCurrentUser() {
  // In production/demo mode, return demo user
  if (import.meta.env.PROD) {
    return {
      user: { id: DEMO_USER.id, email: DEMO_USER.email },
      profile: DEMO_USER
    };
  }

  try {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) throw error;
    
    if (!session?.user) {
      return { user: null, profile: null };
    }

    return {
      user: session.user,
      profile: {
        id: session.user.id,
        name: session.user.email?.split('@')[0] || 'User',
        email: session.user.email || '',
        role: 'user',
        placeInteractions: {},
        quiz_stats: {
          questionsAnswered: 0,
          correctAnswers: 0,
          correctlyAnsweredIds: [],
          incorrectlyAnsweredIds: []
        }
      }
    };
  } catch (error) {
    console.error('Error getting current user:', error);
    return { user: null, profile: null };
  }
}