import React from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { X } from 'lucide-react';
import { useAppState } from '../lib/state';

interface AboutMeOverlayProps {
  onClose: () => void;
  userProfile?: { name: string };
}

export function AboutMeOverlay({ onClose, userProfile }: AboutMeOverlayProps) {
  // Get preferences and update function from Zustand
  const { preferences, updatePreferences } = useAppState();

  const handleSave = () => {
    onClose();
  };

  return (
    <OverlayWrapper>
      <div className="fixed inset-4 bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl shadow-xl overflow-hidden">
        <div className="h-full flex flex-col">
          <div className="flex justify-between items-center p-6 border-b border-white/10">
            <h2 className="text-xl font-bold text-white">About Me</h2>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-white/10 rounded-lg text-white"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {/* Skip Button */}
          <button
            onClick={onClose}
            className="mx-6 mt-4 px-4 py-3 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors"
          >
            Skip for now
          </button>

          <div className="flex-1 overflow-y-auto p-6 pt-2">
            <div className="space-y-6">
              {/* My Status */}
              <div className="space-y-3">
                <h3 className="text-lg font-medium text-white">My Status</h3>
                <label className="flex items-center gap-3 text-white cursor-pointer">
                  <input
                    type="checkbox"
                    checked={preferences.isResident}
                    onChange={(e) => updatePreferences({ isResident: e.target.checked })}
                    className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                  />
                  <span>I am a Cambridge resident</span>
                </label>
                <label className="flex items-center gap-3 text-white cursor-pointer">
                  <input
                    type="checkbox"
                    checked={preferences.isEnglishSpeaker}
                    onChange={(e) => updatePreferences({ isEnglishSpeaker: e.target.checked })}
                    className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                  />
                  <span>English is my primary language</span>
                </label>
              </div>

              {/* My Visit */}
              <div className="space-y-3">
                <h3 className="text-lg font-medium text-white">My Visit</h3>
                <label className="flex items-center gap-3 text-white cursor-pointer">
                  <input
                    type="checkbox"
                    checked={preferences.isDayVisitor}
                    onChange={(e) => updatePreferences({ isDayVisitor: e.target.checked })}
                    className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                  />
                  <span>I'm visiting for the day</span>
                </label>
                <label className="flex items-center gap-3 text-white cursor-pointer">
                  <input
                    type="checkbox"
                    checked={preferences.isShortStay}
                    onChange={(e) => updatePreferences({ isShortStay: e.target.checked })}
                    className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                  />
                  <span>I'm staying for a few days</span>
                </label>
              </div>

              {/* About Me */}
              <div className="space-y-3">
                <h3 className="text-lg font-medium text-white">About Me</h3>
                <label className="flex items-center gap-3 text-white cursor-pointer">
                  <input
                    type="checkbox"
                    checked={preferences.isStudent}
                    onChange={(e) => updatePreferences({ isStudent: e.target.checked })}
                    className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                  />
                  <span>I am a student</span>
                </label>
                <label className="flex items-center gap-3 text-white cursor-pointer">
                  <input
                    type="checkbox"
                    checked={preferences.hasChildren}
                    onChange={(e) => updatePreferences({ hasChildren: e.target.checked })}
                    className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                  />
                  <span>I'm visiting with children</span>
                </label>
              </div>

              {/* My Interests */}
              <div className="space-y-3">
                <h3 className="text-lg font-medium text-white">My Interests</h3>
                <label className="flex items-center gap-3 text-white cursor-pointer">
                  <input
                    type="checkbox"
                    checked={preferences.interestedInHistory}
                    onChange={(e) => updatePreferences({ interestedInHistory: e.target.checked })}
                    className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                  />
                  <span>History and Heritage</span>
                </label>
                <label className="flex items-center gap-3 text-white cursor-pointer">
                  <input
                    type="checkbox"
                    checked={preferences.interestedInArts}
                    onChange={(e) => updatePreferences({ interestedInArts: e.target.checked })}
                    className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                  />
                  <span>Arts and Culture</span>
                </label>
                <label className="flex items-center gap-3 text-white cursor-pointer">
                  <input
                    type="checkbox"
                    checked={preferences.interestedInNature}
                    onChange={(e) => updatePreferences({ interestedInNature: e.target.checked })}
                    className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                  />
                  <span>Nature and Outdoors</span>
                </label>
              </div>

              {/* Getting Around */}
              <div className="space-y-3">
                <h3 className="text-lg font-medium text-white">Getting Around</h3>
                <label className="flex items-center gap-3 text-white cursor-pointer">
                  <input
                    type="checkbox"
                    checked={preferences.usePublicTransport}
                    onChange={(e) => updatePreferences({ usePublicTransport: e.target.checked })}
                    className="w-5 h-5 rounded border-white/20 bg-white/10 checked:bg-indigo-600"
                  />
                  <span>I use public transport</span>
                </label>
              </div>

              {/* Save Button */}
              <button
                onClick={handleSave}
                className="w-full mt-6 px-6 py-3 bg-indigo-600 hover:bg-indigo-500 text-white rounded-lg transition-colors flex items-center justify-center gap-2"
              >
                Save Preferences
              </button>
            </div>
          </div>
        </div>
      </div>
    </OverlayWrapper>
  );
}