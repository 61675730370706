import React, { useState, useEffect } from 'react';
import { DollarSign, Calendar, Clock, AlertCircle, Trophy } from 'lucide-react';
import type { BidSlot, BidHistory } from '../../types';
import { getCurrentBids, getBidHistory, createBidSlot } from '../../lib/scoring';
import { format, addDays } from 'date-fns';

export function BiddingInterface({ placeId }: { placeId: string }) {
  const [currentBids, setCurrentBids] = useState<BidSlot[]>([]);
  const [bidHistory, setBidHistory] = useState<BidHistory[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showBidForm, setShowBidForm] = useState(false);

  const [bidForm, setBidForm] = useState({
    position: 1,
    amount: 100,
    duration: 7
  });

  useEffect(() => {
    loadData();
  }, [placeId]);

  async function loadData() {
    try {
      const [bids, history] = await Promise.all([
        getCurrentBids(),
        getBidHistory(placeId)
      ]);
      setCurrentBids(bids);
      setBidHistory(history);
      setLoading(false);
    } catch (err) {
      setError('Failed to load bidding data');
      setLoading(false);
    }
  }

  const handleBidSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await createBidSlot(
        placeId,
        bidForm.position,
        bidForm.amount,
        bidForm.duration
      );
      setShowBidForm(false);
      loadData();
    } catch (err) {
      setError('Failed to place bid');
    }
  };

  if (loading) {
    return (
      <div className="p-6 text-center">
        <div className="w-8 h-8 border-4 border-indigo-600 border-t-transparent rounded-full animate-spin mx-auto" />
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Current Position */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-bold text-gray-900 mb-4">Featured Position</h2>
        {currentBids.some(bid => bid.placeId === placeId) ? (
          currentBids
            .filter(bid => bid.placeId === placeId)
            .map(bid => (
              <div key={bid.id} className="bg-indigo-50 p-4 rounded-lg">
                <div className="flex items-center gap-4">
                  <div className="w-16 h-16 bg-indigo-100 rounded-lg flex items-center justify-center">
                    <Trophy className="w-8 h-8 text-indigo-600" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">
                      Position #{bid.position}
                    </h3>
                    <div className="flex gap-4 mt-2 text-sm text-gray-600">
                      <div className="flex items-center gap-1">
                        <DollarSign className="w-4 h-4" />
                        <span>£{bid.amount.toFixed(2)}/week</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <Clock className="w-4 h-4" />
                        <span>
                          {Math.ceil(
                            (new Date(bid.endDate).getTime() - new Date().getTime()) /
                            (1000 * 60 * 60 * 24)
                          )} days remaining
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
        ) : (
          <div className="text-center py-8">
            <Trophy className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-600">
              You don't have any active featured positions.
            </p>
            <button
              onClick={() => setShowBidForm(true)}
              className="mt-4 px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            >
              Place a Bid
            </button>
          </div>
        )}
      </div>

      {/* Bid Form */}
      {showBidForm && (
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">Place a Bid</h2>
          <form onSubmit={handleBidSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Position
              </label>
              <select
                value={bidForm.position}
                onChange={e => setBidForm(prev => ({ ...prev, position: parseInt(e.target.value) }))}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              >
                {[1, 2, 3, 4, 5].map(pos => (
                  <option key={pos} value={pos}>
                    Position #{pos}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Bid Amount (£/week)
              </label>
              <input
                type="number"
                min="50"
                step="10"
                value={bidForm.amount}
                onChange={e => setBidForm(prev => ({ ...prev, amount: parseInt(e.target.value) }))}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Duration (days)
              </label>
              <select
                value={bidForm.duration}
                onChange={e => setBidForm(prev => ({ ...prev, duration: parseInt(e.target.value) }))}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              >
                <option value={7}>1 week</option>
                <option value={14}>2 weeks</option>
                <option value={30}>1 month</option>
              </select>
            </div>

            <div className="bg-blue-50 p-4 rounded-lg flex items-start gap-3">
              <AlertCircle className="w-5 h-5 text-blue-600 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-blue-700">
                <p className="font-medium mb-1">Bidding Information</p>
                <ul className="list-disc list-inside space-y-1">
                  <li>Minimum bid is £50 per week</li>
                  <li>Higher positions have more visibility</li>
                  <li>Bids are automatically renewed unless cancelled</li>
                  <li>You can modify your bid at any time</li>
                </ul>
              </div>
            </div>

            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={() => setShowBidForm(false)}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Place Bid
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Bid History */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-bold text-gray-900 mb-4">Bid History</h2>
        <div className="space-y-4">
          {bidHistory.map(bid => (
            <div
              key={bid.id}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
            >
              <div>
                <div className="flex items-center gap-2">
                  <span className="font-medium text-gray-900">
                    Position #{bid.position}
                  </span>
                  <span className={`px-2 py-0.5 rounded-full text-xs font-medium
                    ${bid.status === 'won' ? 'bg-green-100 text-green-800' :
                      bid.status === 'lost' ? 'bg-red-100 text-red-800' :
                      'bg-gray-100 text-gray-800'}`}
                  >
                    {bid.status}
                  </span>
                </div>
                <div className="flex gap-4 mt-1 text-sm text-gray-500">
                  <div className="flex items-center gap-1">
                    <DollarSign className="w-4 h-4" />
                    <span>£{bid.amount.toFixed(2)}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <Calendar className="w-4 h-4" />
                    <span>{format(new Date(bid.createdAt), 'MMM d, yyyy')}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {bidHistory.length === 0 && (
            <div className="text-center py-8 text-gray-500">
              No bid history available
            </div>
          )}
        </div>
      </div>
    </div>
  );
}