import React, { useState, useEffect } from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { X, MapPin, Star, Gift, Heart, Brain, Sparkles, Key, Store } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface WelcomeSplashOverlayProps {
  onClose: () => void;
  onGetStarted: () => void;
}

const welcomeMessages = [
  { lang: 'en', text: 'Welcome to Cambridge' },
  { lang: 'fr', text: 'Bienvenue à Cambridge' },
  { lang: 'es', text: 'Bienvenido a Cambridge' },
  { lang: 'de', text: 'Willkommen in Cambridge' },
  { lang: 'it', text: 'Benvenuti a Cambridge' },
  { lang: 'zh', text: '欢迎来到剑桥' },
  { lang: 'ja', text: 'ケンブリッジへようこそ' }
];

export function WelcomeSplashOverlay({ onClose, onGetStarted }: WelcomeSplashOverlayProps) {
  const { t } = useTranslation();
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  // Rotate through welcome messages
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex(prev => (prev + 1) % welcomeMessages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <OverlayWrapper>
      <div className="fixed inset-4 bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl shadow-xl overflow-hidden">
        <div className="h-full flex flex-col">
          {/* Header */}
          <div className="relative h-64">
            <img
              src="https://images.unsplash.com/photo-1564463836146-4e30522c2984?w=1200&auto=format&fit=crop&q=80"
              alt="Kings College Cambridge"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-indigo-900/90" />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-center">
                <h1 className="text-4xl font-bold text-white mb-2 transition-all duration-500">
                  {welcomeMessages[currentMessageIndex].text}
                </h1>
                <p className="text-xl text-white/90 mb-6">Your Personal City Guide</p>
                <button
                  onClick={onClose}
                  className="p-4 bg-gradient-to-r from-indigo-400 to-purple-400 hover:from-indigo-500 
                    hover:to-purple-500 rounded-full transition-all duration-300 shadow-lg 
                    hover:scale-110 active:scale-95"
                  title="Skip intro"
                >
                  <Key className="w-8 h-8 text-white drop-shadow" strokeWidth={2.5} />
                </button>
              </div>
            </div>
          </div>

          {/* Features Grid */}
          <div className="flex-1 overflow-y-auto p-6">
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-white/10 rounded-xl p-4">
                <div className="flex items-center gap-2 mb-2">
                  <MapPin className="w-5 h-5 text-blue-400" />
                  <h3 className="font-medium text-white">Local Insights</h3>
                </div>
                <p className="text-sm text-white/70">
                  Discover hidden gems and local favourites
                </p>
              </div>

              <div className="bg-white/10 rounded-xl p-4">
                <div className="flex items-center gap-2 mb-2">
                  <Gift className="w-5 h-5 text-purple-400" />
                  <h3 className="font-medium text-white">Special Offers</h3>
                </div>
                <p className="text-sm text-white/70">
                  Exclusive deals and discounts
                </p>
              </div>

              <div className="bg-white/10 rounded-xl p-4">
                <div className="flex items-center gap-2 mb-2">
                  <Star className="w-5 h-5 text-yellow-400" />
                  <h3 className="font-medium text-white">Reviews</h3>
                </div>
                <p className="text-sm text-white/70">
                  Real experiences from visitors
                </p>
              </div>

              <div className="bg-white/10 rounded-xl p-4">
                <div className="flex items-center gap-2 mb-2">
                  <Brain className="w-5 h-5 text-green-400" />
                  <h3 className="font-medium text-white">Cambridge Quiz</h3>
                </div>
                <p className="text-sm text-white/70">
                  Test your knowledge & earn points
                </p>
              </div>
            </div>

            {/* Get Started Button */}
            <button
              onClick={onGetStarted}
              className="w-full mt-6 px-6 py-3 bg-white text-indigo-900 rounded-lg font-medium
                hover:bg-white/90 transition-colors flex items-center justify-center gap-2"
            >
              <Sparkles className="w-5 h-5" />
              Get Started
            </button>

            {/* Business Owner CTA */}
            <div className="mt-6 pt-6 border-t border-white/10 text-center">
              <p className="text-white/90 mb-2">
                Are you a local business owner?
              </p>
              <p className="text-white/70 text-sm mb-4">
                Would you like to add your venue or activity to our app?
              </p>
              <button
                onClick={() => {/* Business owner flow */}}
                className="px-6 py-3 bg-white/10 hover:bg-white/20 text-white rounded-lg 
                  transition-colors flex items-center justify-center gap-2 mx-auto"
              >
                <Store className="w-5 h-5" />
                Find Out More
              </button>
            </div>
          </div>
        </div>
      </div>
    </OverlayWrapper>
  );
}