import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { Bell, Globe, Menu, Gift, X, Heart, BookmarkPlus, Info, Trophy, Brain, CheckCircle, Star, Sparkles, Share2 } from 'lucide-react';
import { LanguagesOverlay } from './LanguagesOverlay';
import { AccountOverlay } from './AccountOverlay';
import { useTranslation } from 'react-i18next';
import { useAppState } from '../lib/state';

interface HeaderProps {
  isSignedIn: boolean;
  userProfile?: { name: string };
  userPoints: number;
  onSignUp: () => void;
  onSignIn: () => void;
  onShowProfile: () => void;
  onShowSpecialOffers: () => void;
  onShowInfo: () => void;
  onShowReviews: () => void;
  onShowVisited: () => void;
  onShowFavorites: () => void;
  onShowBookmarks: () => void;
  onShowBonusPoints: () => void;
  onShowShares: () => void;
  quizStats?: {
    questionsAnswered: number;
    correctAnswers: number;
  };
  heroButtonText: 'joinToday' | 'specialOffers';
}

export function Header({ 
  isSignedIn,
  userProfile,
  userPoints,
  onSignUp,
  onSignIn,
  onShowProfile,
  onShowSpecialOffers,
  onShowInfo,
  onShowReviews,
  onShowVisited,
  onShowFavorites,
  onShowBookmarks,
  onShowBonusPoints,
  onShowShares,
  quizStats,
  heroButtonText
}: HeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const [showAccount, setShowAccount] = useState(false);

  const { signOut, favourites, bookmarks, visitedPlaces, getAllReviews, shares } = useAppState();
  const reviews = getAllReviews();

  // Reset menu state when auth status changes
  useEffect(() => {
    setIsMenuOpen(false);
    setShowAccount(false);
  }, [isSignedIn]);

  // Count special offers
  const specialOffersCount = 0; // This will be updated when we implement special offers

  const percentageCorrect = quizStats && quizStats.questionsAnswered > 0
    ? Math.round((quizStats.correctAnswers / quizStats.questionsAnswered) * 100)
    : 0;

  const handleHeroButtonClick = () => {
    if (isSignedIn) {
      onShowSpecialOffers();
    } else {
      onSignUp();
    }
    setIsMenuOpen(false);
  };

  const handleSignOut = async () => {
    setIsMenuOpen(false); // Close menu first
    setShowAccount(false); // Close account overlay if open
    await signOut(); // Wait for sign out to complete
    navigate('/'); // Navigate to home page
  };

  return (
    <header className="md:hidden bg-[#1A1F2B] text-white">
      <div className="flex items-center justify-between px-4 h-14">
        <span className="text-xl font-bold">mycam.city</span>
        <div className="flex items-center gap-4">
          <button 
            className="p-2 relative"
            onClick={() => {}}
          >
            <Bell className="w-6 h-6" />
            <span className="absolute -top-1 -right-1 w-4 h-4 bg-red-500 rounded-full text-xs flex items-center justify-center">
              2
            </span>
          </button>
          <button 
            className="p-2"
            onClick={() => setShowLanguages(true)}
          >
            <Globe className="w-6 h-6" />
          </button>
          <button 
            className="p-2" 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
          </button>
        </div>
      </div>

      {/* Hero Section */}
      <div 
        className="relative h-48 bg-cover bg-center px-4 py-6"
        style={{
          backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(https://images.unsplash.com/photo-1564463836146-4e30522c2984?w=1200&auto=format&fit=crop&q=80)'
        }}
      >
        <div className="h-full flex flex-col justify-between">
          <div>
            <h1 className="text-2xl font-bold mb-2">{t('hero.title')}</h1>
            <p className="text-sm text-white/90">{t('hero.subtitle')}</p>
          </div>
          
          <div className="flex items-end justify-between">
            <button
              onClick={handleHeroButtonClick}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg flex items-center gap-2 hover:bg-indigo-700 transition-colors"
            >
              {t(`hero.${heroButtonText}`)}
            </button>

            {isSignedIn && (
              <div className="flex gap-2">
                <button
                  onClick={() => onShowBookmarks()}
                  className="relative p-2 hover:bg-black/20 rounded-lg transition-colors"
                  title="Bookmarks"
                >
                  <BookmarkPlus className="w-6 h-6 text-white" />
                  {bookmarks.length > 0 && (
                    <span className="absolute -top-1 -right-1 bg-purple-500 text-white w-4 h-4 flex items-center justify-center rounded-full text-xs">
                      {bookmarks.length}
                    </span>
                  )}
                </button>

                <button
                  onClick={() => onShowFavorites()}
                  className="relative p-2 hover:bg-black/20 rounded-lg transition-colors"
                  title="Favourites"
                >
                  <Heart className="w-6 h-6 text-white" />
                  {favourites.length > 0 && (
                    <span className="absolute -top-1 -right-1 bg-red-500 text-white w-4 h-4 flex items-center justify-center rounded-full text-xs">
                      {favourites.length}
                    </span>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Menu Overlay */}
      {isMenuOpen && (
        <div className="absolute top-14 left-0 right-0 bg-gradient-to-br from-indigo-900 via-purple-900 to-[#1A1F2B] border-t border-white/10 rounded-b-xl shadow-xl z-50">
          {!isSignedIn ? (
            <div className="p-4 space-y-2">
              <button 
                onClick={() => {
                  onSignUp();
                  setIsMenuOpen(false);
                }}
                className="w-full px-4 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Sign Up
              </button>

              <button
                onClick={() => {
                  onSignIn();
                  setIsMenuOpen(false);
                }}
                className="w-full px-4 py-3 bg-white/10 text-white rounded-lg hover:bg-white/20"
              >
                Sign In
              </button>

              <div className="border-t border-white/10 my-2" />

              <button
                onClick={() => {
                  onShowInfo();
                  setIsMenuOpen(false);
                }}
                className="w-full px-4 py-3 flex items-center gap-3 rounded-lg hover:bg-white/10"
              >
                <Info className="w-5 h-5 text-blue-400" />
                <span>Benefits of Joining</span>
              </button>
            </div>
          ) : (
            <div className="p-4 space-y-2">
              <div className="w-full px-4 py-3 flex items-center justify-between gap-3 rounded-lg">
                <div className="text-left">
                  <span className="block font-medium">{userProfile?.name}</span>
                  <span className="text-sm text-white/70">Account Settings</span>
                </div>
                <button
                  onClick={() => {
                    setShowAccount(true);
                    setIsMenuOpen(false);
                  }}
                  className="px-4 py-2 bg-white/10 hover:bg-white/20 rounded-lg transition-colors"
                >
                  Manage
                </button>
              </div>

              <div className="border-t border-white/10 my-2" />

              <button
                onClick={() => {
                  onShowBonusPoints();
                  setIsMenuOpen(false);
                }}
                className="w-full px-4 py-3 flex items-center justify-between rounded-lg hover:bg-white/10"
              >
                <div className="flex items-center gap-3">
                  <Trophy className="w-5 h-5 text-yellow-400" />
                  <span>My Bonus Points</span>
                </div>
                <span className="bg-yellow-500 text-white px-2 py-0.5 rounded-full text-sm">
                  {userPoints}
                </span>
              </button>

              <button
                onClick={() => {
                  onShowFavorites();
                  setIsMenuOpen(false);
                }}
                className="w-full px-4 py-3 flex items-center justify-between rounded-lg hover:bg-white/10"
              >
                <div className="flex items-center gap-3">
                  <Heart className="w-5 h-5 text-red-400" />
                  <span>My Favourites</span>
                </div>
                {favourites.length > 0 && (
                  <span className="bg-red-500 text-white px-2 py-0.5 rounded-full text-sm">
                    {favourites.length}
                  </span>
                )}
              </button>

              <button
                onClick={() => {
                  onShowBookmarks();
                  setIsMenuOpen(false);
                }}
                className="w-full px-4 py-3 flex items-center justify-between rounded-lg hover:bg-white/10"
              >
                <div className="flex items-center gap-3">
                  <BookmarkPlus className="w-5 h-5 text-purple-400" />
                  <span>My Bookmarks</span>
                </div>
                {bookmarks.length > 0 && (
                  <span className="bg-purple-500 text-white px-2 py-0.5 rounded-full text-sm">
                    {bookmarks.length}
                  </span>
                )}
              </button>

              <button
                onClick={() => {
                  onShowVisited();
                  setIsMenuOpen(false);
                }}
                className="w-full px-4 py-3 flex items-center justify-between rounded-lg hover:bg-white/10"
              >
                <div className="flex items-center gap-3">
                  <CheckCircle className="w-5 h-5 text-green-400" />
                  <span>Places Visited</span>
                </div>
                {visitedPlaces.size > 0 && (
                  <span className="bg-green-500 text-white px-2 py-0.5 rounded-full text-sm">
                    {visitedPlaces.size}
                  </span>
                )}
              </button>

              <button
                onClick={() => {
                  onShowReviews();
                  setIsMenuOpen(false);
                }}
                className="w-full px-4 py-3 flex items-center justify-between rounded-lg hover:bg-white/10"
              >
                <div className="flex items-center gap-3">
                  <Star className="w-5 h-5 text-yellow-400" />
                  <span>My Reviews</span>
                </div>
                {reviews.length > 0 && (
                  <span className="bg-yellow-500 text-white px-2 py-0.5 rounded-full text-sm">
                    {reviews.length}
                  </span>
                )}
              </button>

              <button
                onClick={() => {
                  onShowShares();
                  setIsMenuOpen(false);
                }}
                className="w-full px-4 py-3 flex items-center justify-between rounded-lg hover:bg-white/10"
              >
                <div className="flex items-center gap-3">
                  <Share2 className="w-5 h-5 text-blue-400" />
                  <span>My Shares</span>
                </div>
                {shares.size > 0 && (
                  <span className="bg-blue-500 text-white px-2 py-0.5 rounded-full text-sm">
                    {shares.size}
                  </span>
                )}
              </button>

              <button
                onClick={() => {
                  onShowSpecialOffers();
                  setIsMenuOpen(false);
                }}
                className="w-full px-4 py-3 flex items-center justify-between rounded-lg hover:bg-white/10"
              >
                <div className="flex items-center gap-3">
                  <Gift className="w-5 h-5 text-yellow-400" />
                  <span>Special Offers</span>
                </div>
                {specialOffersCount > 0 && (
                  <span className="bg-yellow-500 text-white px-2 py-0.5 rounded-full text-sm">
                    {specialOffersCount}
                  </span>
                )}
              </button>

              {quizStats && (
                <button
                  onClick={() => {
                    setIsMenuOpen(false);
                    const quizSection = document.querySelector('.quiz-section');
                    if (quizSection) {
                      quizSection.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                  className="w-full px-4 py-3 flex items-center justify-between rounded-lg bg-white/5 hover:bg-white/10"
                >
                  <div className="flex items-center gap-3">
                    <Brain className="w-5 h-5 text-indigo-400" />
                    <span>Quiz Score</span>
                  </div>
                  <div className="text-sm text-white/70">
                    {percentageCorrect}% ({quizStats.correctAnswers}/{quizStats.questionsAnswered})
                  </div>
                </button>
              )}

              <div className="border-t border-white/10 my-2" />
              <button
                onClick={handleSignOut}
                className="w-full px-4 py-3 text-red-400 hover:bg-red-500/10 rounded-lg transition-colors"
              >
                Sign Out
              </button>
            </div>
          )}
        </div>
      )}

      {/* Language Overlay */}
      {showLanguages && (
        <LanguagesOverlay
          onClose={() => setShowLanguages(false)}
          onLanguageSelect={(lang) => {}}
        />
      )}

      {/* Account Overlay */}
      {showAccount && (
        <AccountOverlay
          onClose={() => setShowAccount(false)}
          userProfile={userProfile}
        />
      )}
    </header>
  );
}