import React, { useEffect } from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { X, Heart, BookmarkPlus, Star, CheckCircle, Brain, Share2 } from 'lucide-react';
import { useAppState } from '../lib/state';

interface BonusPointsNotificationProps {
  points: number;
  action: 'favourite' | 'bookmark' | 'review' | 'visited' | 'quiz' | 'share';
  onClose: () => void;
  isSignedIn?: boolean;
}

export function BonusPointsNotification({ points, action, onClose, isSignedIn = true }: BonusPointsNotificationProps) {
  const { clearLastBonusPoints } = useAppState();

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    clearLastBonusPoints();
    onClose();
  };

  const getIcon = () => {
    switch (action) {
      case 'favourite':
        return <Heart className="w-5 h-5 text-red-400" />;
      case 'bookmark':
        return <BookmarkPlus className="w-5 h-5 text-purple-400" />;
      case 'review':
        return <Star className="w-5 h-5 text-yellow-400" />;
      case 'visited':
        return <CheckCircle className="w-5 h-5 text-green-400" />;
      case 'quiz':
        return <Brain className="w-5 h-5 text-blue-400" />;
      case 'share':
        return <Share2 className="w-5 h-5 text-blue-400" />;
      default:
        return null;
    }
  };

  const getMessage = () => {
    if (!isSignedIn) {
      switch (action) {
        case 'favourite':
          return '+50 bonus points for adding your first favourite when you join MyCam.City';
        case 'bookmark':
          return '+50 bonus points for adding your first bookmark when you join MyCam.City';
        case 'review':
          return '+100 bonus points for writing your first review when you join MyCam.City';
        case 'visited':
          return '+25 bonus points for marking your first visited place when you join MyCam.City';
        case 'share':
          return '+50 bonus points for sharing your first place when you join MyCam.City';
        default:
          return '';
      }
    }

    switch (action) {
      case 'favourite':
        return 'for adding your first favourite!';
      case 'bookmark':
        return 'for creating your first bookmark!';
      case 'review':
        return 'for writing your first review!';
      case 'visited':
        return 'for marking your first visited place!';
      case 'quiz':
        return 'for your correct quiz answer!';
      case 'share':
        return 'for sharing your first place!';
      default:
        return '';
    }
  };

  return (
    <OverlayWrapper>
      <div className="fixed bottom-4 left-1/2 -translate-x-1/2 bg-white rounded-lg shadow-lg p-4 flex items-center gap-4 z-50">
        <div className="w-12 h-12 bg-indigo-50 rounded-full flex items-center justify-center">
          {getIcon()}
        </div>
        <div>
          {isSignedIn && (
            <div className="flex items-center gap-2">
              <span className="font-bold text-yellow-500">+{points}</span>
              <span className="text-gray-600">bonus points</span>
            </div>
          )}
          <p className="text-sm text-gray-500">{getMessage()}</p>
        </div>
        <button
          onClick={handleClose}
          className="p-2 hover:bg-gray-100 rounded-full ml-2"
        >
          <X className="w-5 h-5 text-gray-400" />
        </button>
      </div>
    </OverlayWrapper>
  );
}