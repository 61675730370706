import React from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { X, Heart, BookmarkPlus, Star, Gift, Trophy, Coins, CheckCircle } from 'lucide-react';

interface BonusPointsOverlayProps {
  onClose: () => void;
  points: number;
}

export function BonusPointsOverlay({ onClose, points }: BonusPointsOverlayProps) {
  return (
    <OverlayWrapper>
      <div className="fixed inset-4 bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl shadow-xl overflow-hidden">
        {/* Close button */}
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 p-2 text-white hover:bg-white/10 rounded-lg z-10"
          aria-label="Close"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="h-full flex flex-col">
          {/* Header */}
          <div className="flex items-center p-6 border-b border-white/10">
            <div className="flex items-center gap-3">
              <Coins className="w-8 h-8 text-yellow-400" />
              <div>
                <h2 className="text-xl font-bold text-white">My Bonus Points</h2>
                <p className="text-white/70 text-sm">Earn points by exploring Cambridge</p>
              </div>
            </div>
            <div className="ml-auto">
              <div className="px-4 py-2 bg-yellow-500 text-white rounded-lg font-bold">
                {points} points
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="flex-1 p-6 overflow-y-auto">
            <div className="max-w-md mx-auto space-y-8">
              <div className="text-center">
                <h3 className="text-2xl font-bold text-white mb-2">How to Earn Points</h3>
                <p className="text-white/80">
                  Earn bonus points by interacting with places and sharing your experiences!
                </p>
              </div>

              <div className="space-y-4">
                <div className="bg-white/10 rounded-xl p-4 transform hover:scale-105 transition-transform">
                  <div className="flex items-center gap-3">
                    <Heart className="w-8 h-8 text-red-400" />
                    <div className="flex-1">
                      <h3 className="font-semibold text-white">Add Favorites</h3>
                      <p className="text-white/70 text-sm">Mark places you love</p>
                    </div>
                    <span className="text-yellow-400 font-bold">+50 points</span>
                  </div>
                </div>

                <div className="bg-white/10 rounded-xl p-4 transform hover:scale-105 transition-transform">
                  <div className="flex items-center gap-3">
                    <BookmarkPlus className="w-8 h-8 text-purple-400" />
                    <div className="flex-1">
                      <h3 className="font-semibold text-white">Create Bookmarks</h3>
                      <p className="text-white/70 text-sm">Save places to visit later</p>
                    </div>
                    <span className="text-yellow-400 font-bold">+50 points</span>
                  </div>
                </div>

                <div className="bg-white/10 rounded-xl p-4 transform hover:scale-105 transition-transform">
                  <div className="flex items-center gap-3">
                    <Star className="w-8 h-8 text-yellow-400" />
                    <div className="flex-1">
                      <h3 className="font-semibold text-white">Write Reviews</h3>
                      <p className="text-white/70 text-sm">Share your experiences</p>
                    </div>
                    <span className="text-yellow-400 font-bold">+100 points</span>
                  </div>
                </div>

                <div className="bg-white/10 rounded-xl p-4 transform hover:scale-105 transition-transform">
                  <div className="flex items-center gap-3">
                    <Trophy className="w-8 h-8 text-green-400" />
                    <div className="flex-1">
                      <h3 className="font-semibold text-white">Quiz Answers</h3>
                      <p className="text-white/70 text-sm">Test your Cambridge knowledge</p>
                    </div>
                    <span className="text-yellow-400 font-bold">+25 points</span>
                  </div>
                </div>

                <div className="bg-white/10 rounded-xl p-4 transform hover:scale-105 transition-transform">
                  <div className="flex items-center gap-3">
                    <Gift className="w-8 h-8 text-indigo-400" />
                    <div className="flex-1">
                      <h3 className="font-semibold text-white">Use Special Offers</h3>
                      <p className="text-white/70 text-sm">Claim exclusive deals</p>
                    </div>
                    <span className="text-yellow-400 font-bold">+75 points</span>
                  </div>
                </div>

                <div className="bg-white/10 rounded-xl p-4 transform hover:scale-105 transition-transform">
                  <div className="flex items-center gap-3">
                    <CheckCircle className="w-8 h-8 text-blue-400" />
                    <div className="flex-1">
                      <h3 className="font-semibold text-white">Mark Places Visited</h3>
                      <p className="text-white/70 text-sm">Track your adventures</p>
                    </div>
                    <span className="text-yellow-400 font-bold">+25 points</span>
                  </div>
                </div>
              </div>

              <div className="bg-white/10 rounded-xl p-6 text-center">
                <h3 className="text-xl font-bold text-white mb-2">Coming Soon!</h3>
                <p className="text-white/80">
                  Soon you'll be able to redeem your points for exclusive rewards and discounts at participating venues!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OverlayWrapper>
  );
}