import { encode, decode } from '../utils/base64';

interface ReferralData {
  userId: string;
  placeId: string;
  shareId: string;
}

export function createReferralCode(userId: string, placeId: string, shareId: string): string {
  const data: ReferralData = { userId, placeId, shareId };
  return encode(JSON.stringify(data));
}

export function parseReferralCode(code: string): ReferralData | null {
  try {
    const decoded = decode(code);
    return JSON.parse(decoded) as ReferralData;
  } catch {
    return null;
  }
}

// Store referral data in session storage until signup
export function storeReferralData(data: ReferralData): void {
  sessionStorage.setItem('referral_data', JSON.stringify(data));
}

export function getReferralData(): ReferralData | null {
  const stored = sessionStorage.getItem('referral_data');
  if (!stored) return null;
  
  try {
    return JSON.parse(stored) as ReferralData;
  } catch {
    return null;
  }
}

export function clearReferralData(): void {
  sessionStorage.removeItem('referral_data');
}