import React, { useState } from 'react';
import { BiddingInterface } from './BiddingInterface';
import { ServiceTiers } from './ServiceTiers';
import { NotificationManager } from './NotificationManager';
import { PLACES } from '../../data/places';
import { Layers, Star, Bell, Trophy } from 'lucide-react';

interface BusinessDashboardProps {
  placeId: string;
}

export function BusinessDashboard({ placeId }: BusinessDashboardProps) {
  const [activeTab, setActiveTab] = useState<'services' | 'featured' | 'notifications'>('services');
  const place = PLACES.find(p => p.id === placeId);
  
  if (!place) return null;

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Business Dashboard</h1>
          <div className="text-sm text-gray-500">
            Managing: {place.name}
          </div>
        </div>

        {/* Tabs */}
        <div className="bg-white rounded-lg shadow-md mb-8">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px">
              <button
                onClick={() => setActiveTab('services')}
                className={`flex items-center gap-2 px-6 py-4 text-sm font-medium border-b-2 transition-colors
                  ${activeTab === 'services' 
                    ? 'border-indigo-600 text-indigo-600' 
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
              >
                <Layers className="w-5 h-5" />
                Service Tiers
              </button>

              <button
                onClick={() => setActiveTab('featured')}
                className={`flex items-center gap-2 px-6 py-4 text-sm font-medium border-b-2 transition-colors
                  ${activeTab === 'featured' 
                    ? 'border-indigo-600 text-indigo-600' 
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
              >
                <Trophy className="w-5 h-5" />
                Featured Positions
              </button>

              <button
                onClick={() => setActiveTab('notifications')}
                className={`flex items-center gap-2 px-6 py-4 text-sm font-medium border-b-2 transition-colors
                  ${activeTab === 'notifications' 
                    ? 'border-indigo-600 text-indigo-600' 
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
              >
                <Bell className="w-5 h-5" />
                Notifications
              </button>
            </nav>
          </div>
        </div>

        {/* Tab Content */}
        <div className="space-y-8">
          {activeTab === 'services' && (
            <ServiceTiers placeId={placeId} />
          )}

          {activeTab === 'featured' && (
            <BiddingInterface placeId={placeId} />
          )}

          {activeTab === 'notifications' && (
            <NotificationManager placeId={placeId} />
          )}
        </div>
      </div>
    </div>
  );
}