import { supabase } from './supabase';
import type { ScoringCriteria, PlaceScore, BidSlot, BidHistory } from '../types';
import { addDays, isAfter, isBefore } from 'date-fns';

// Scoring Criteria Functions
export async function getScoringCriteria(): Promise<ScoringCriteria[]> {
  const { data, error } = await supabase
    .from('scoring_criteria')
    .select('*')
    .order('weight', { ascending: false });

  if (error) throw error;
  return data || [];
}

export async function createScoringCriteria(criteria: Omit<ScoringCriteria, 'id' | 'createdAt' | 'updatedAt'>): Promise<ScoringCriteria> {
  const { data, error } = await supabase
    .from('scoring_criteria')
    .insert([criteria])
    .select()
    .single();

  if (error) throw error;
  return data;
}

// Place Scoring Functions
export async function scorePlaceForCriteria(
  placeId: string,
  criteriaId: string,
  score: number,
  notes?: string
): Promise<PlaceScore> {
  const { data, error } = await supabase
    .from('place_scores')
    .upsert({
      place_id: placeId,
      criteria_id: criteriaId,
      score,
      notes,
      reviewed_at: new Date().toISOString()
    })
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function getPlaceScores(placeId: string): Promise<PlaceScore[]> {
  const { data, error } = await supabase
    .from('place_scores')
    .select(`
      *,
      scoring_criteria (*)
    `)
    .eq('place_id', placeId);

  if (error) throw error;
  return data || [];
}

// Bidding Functions
export async function createBidSlot(
  placeId: string,
  position: number,
  amount: number,
  durationDays: number = 7
): Promise<BidSlot> {
  const startDate = new Date();
  const endDate = addDays(startDate, durationDays);

  // Check for conflicting bids
  const { data: existingBids } = await supabase
    .from('bid_slots')
    .select('*')
    .eq('position', position)
    .eq('status', 'active')
    .or(`start_date.lte.${endDate.toISOString()},end_date.gte.${startDate.toISOString()}`);

  if (existingBids && existingBids.length > 0) {
    throw new Error('Position already taken for the specified time period');
  }

  const { data, error } = await supabase
    .from('bid_slots')
    .insert([{
      place_id: placeId,
      position,
      amount,
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      status: 'pending'
    }])
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function getCurrentBids(): Promise<BidSlot[]> {
  const now = new Date().toISOString();
  
  const { data, error } = await supabase
    .from('bid_slots')
    .select(`
      *,
      places (
        name,
        imageUrl
      )
    `)
    .eq('status', 'active')
    .gte('end_date', now)
    .order('position', { ascending: true });

  if (error) throw error;
  return data || [];
}

export async function getBidHistory(placeId: string): Promise<BidHistory[]> {
  const { data, error } = await supabase
    .from('bid_history')
    .select('*')
    .eq('place_id', placeId)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data || [];
}

// Calculate final ranking score
export async function calculateRankingScore(placeId: string): Promise<number> {
  const [scores, currentBid] = await Promise.all([
    getPlaceScores(placeId),
    getCurrentBids().then(bids => 
      bids.find(bid => bid.place_id === placeId)?.amount || 0
    )
  ]);

  // Calculate weighted average of admin scores
  const totalWeight = scores.reduce((sum, score) => sum + score.scoring_criteria.weight, 0);
  const weightedScore = scores.reduce((sum, score) => 
    sum + (score.score * score.scoring_criteria.weight), 0) / totalWeight;

  // Combine admin score with current bid amount
  // You can adjust these weights based on your business logic
  const ADMIN_SCORE_WEIGHT = 0.7;
  const BID_WEIGHT = 0.3;

  return (weightedScore * ADMIN_SCORE_WEIGHT) + (currentBid * BID_WEIGHT);
}