import React, { useState } from 'react';
import { Bell, Calendar, Users, AlertCircle } from 'lucide-react';

interface NotificationManagerProps {
  placeId: string;
}

export function NotificationManager({ placeId }: NotificationManagerProps) {
  const [notificationType, setNotificationType] = useState<'special-offer' | 'event' | 'announcement'>('special-offer');
  
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold text-gray-900 mb-6">Send Notifications</h2>

      <div className="space-y-6">
        {/* Notification Type Selector */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Notification Type
          </label>
          <div className="grid grid-cols-3 gap-4">
            <button
              onClick={() => setNotificationType('special-offer')}
              className={`p-4 rounded-lg border-2 transition-colors
                ${notificationType === 'special-offer'
                  ? 'border-indigo-600 bg-indigo-50'
                  : 'border-gray-200 hover:border-gray-300'}`}
            >
              <Bell className={`w-6 h-6 mx-auto mb-2
                ${notificationType === 'special-offer' ? 'text-indigo-600' : 'text-gray-400'}`}
              />
              <span className={`block text-sm font-medium
                ${notificationType === 'special-offer' ? 'text-indigo-600' : 'text-gray-600'}`}>
                Special Offer
              </span>
            </button>

            <button
              onClick={() => setNotificationType('event')}
              className={`p-4 rounded-lg border-2 transition-colors
                ${notificationType === 'event'
                  ? 'border-indigo-600 bg-indigo-50'
                  : 'border-gray-200 hover:border-gray-300'}`}
            >
              <Calendar className={`w-6 h-6 mx-auto mb-2
                ${notificationType === 'event' ? 'text-indigo-600' : 'text-gray-400'}`}
              />
              <span className={`block text-sm font-medium
                ${notificationType === 'event' ? 'text-indigo-600' : 'text-gray-600'}`}>
                Event
              </span>
            </button>

            <button
              onClick={() => setNotificationType('announcement')}
              className={`p-4 rounded-lg border-2 transition-colors
                ${notificationType === 'announcement'
                  ? 'border-indigo-600 bg-indigo-50'
                  : 'border-gray-200 hover:border-gray-300'}`}
            >
              <Users className={`w-6 h-6 mx-auto mb-2
                ${notificationType === 'announcement' ? 'text-indigo-600' : 'text-gray-400'}`}
              />
              <span className={`block text-sm font-medium
                ${notificationType === 'announcement' ? 'text-indigo-600' : 'text-gray-600'}`}>
                Announcement
              </span>
            </button>
          </div>
        </div>

        {/* Notification Form */}
        <form className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Title
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              placeholder="Enter notification title"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Message
            </label>
            <textarea
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              rows={4}
              placeholder="Enter notification message"
            />
          </div>

          {/* Premium Feature Notice */}
          <div className="bg-blue-50 p-4 rounded-lg flex items-start gap-3">
            <AlertCircle className="w-5 h-5 text-blue-600 flex-shrink-0 mt-0.5" />
            <div className="text-sm text-blue-700">
              <p className="font-medium mb-1">Premium Feature</p>
              <p>Push notifications require an active Premium subscription</p>
            </div>
          </div>

          <button
            type="submit"
            className="w-full px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
          >
            Send Notification
          </button>
        </form>
      </div>
    </div>
  );
}