import type { Place } from '../types';

export const PLACES: Place[] = [
  {
    id: 'tour-1',
    name: "Pink Floyd's Cambridge Walking Tour",
    description: "Follow in the footsteps of Syd Barrett and Pink Floyd through historic Cambridge. Visit key locations including Syd's childhood home, the Cambridge School of Art where he studied, and the legendary venues where the band performed.\n\nThis immersive tour explores the early days of one of rock's most influential bands, from their formation at Cambridge High School to their groundbreaking performances at local venues. You'll discover how Cambridge's academic atmosphere and creative spirit influenced their psychedelic sound, and see where Syd Barrett spent his later years as a reclusive artist.",
    category: 'tours',
    categories: ['tours', 'history', 'entertainment'],
    imageUrl: "https://images.unsplash.com/photo-1516916759473-600c07bc12d4?w=1200&auto=format&fit=crop&q=80",
    votes: 445,
    location: { lat: 52.2025, lng: 0.1311 },
    websiteUrl: "https://www.visitcambridge.org/pink-floyd-tour",
    bookingUrl: "https://www.visitcambridge.org/pink-floyd-tour/book",
    price: "£15 per person",
    contact: {
      phone: "01223 457574",
      email: "tours@visitcambridge.org",
      address: "Starts at Cambridge Corn Exchange",
      postcode: "CB2 3QB"
    },
    openingHours: {
      monday: "10:00 AM - 4:00 PM",
      tuesday: "10:00 AM - 4:00 PM",
      wednesday: "10:00 AM - 4:00 PM",
      thursday: "10:00 AM - 4:00 PM",
      friday: "10:00 AM - 4:00 PM",
      saturday: "10:00 AM - 5:00 PM",
      sunday: "11:00 AM - 4:00 PM",
      notes: "Tours run daily, weather permitting. Pre-booking recommended."
    },
    specialOffer: {
      description: "Student discount: £3 off with valid ID",
      validUntil: "2024-12-31"
    }
  },
  {
    id: 'punting-1',
    name: "Scudamore's Punting",
    description: "Traditional Cambridge punting company offering guided tours and self-hire punts. The best way to see the College Backs and experience Cambridge from the river.\n\nEstablished in 1910, Scudamore's has been punting on the River Cam for over 100 years. Their expert guides share fascinating stories about Cambridge's history, architecture, and famous alumni. Glide past world-renowned colleges like King's, Trinity, and St John's, while learning about their centuries-old traditions and secrets. The tour offers unique photographic opportunities and perspectives of Cambridge that can't be seen from anywhere else.",
    category: 'tours',
    categories: ['tours', 'cam', 'outdoors'],
    imageUrl: "https://images.unsplash.com/photo-1581881067989-7e3eaf45f4f5?w=1200&auto=format&fit=crop&q=80",
    votes: 432,
    location: { lat: 52.2099, lng: 0.1150 },
    websiteUrl: "https://www.scudamores.com",
    bookingUrl: "https://www.scudamores.com/book-online",
    price: "From £25 per person (guided tours)",
    contact: {
      phone: "01223 359750",
      email: "enquiries@scudamores.com",
      address: "Granta Place, Mill Lane",
      postcode: "CB2 1RS"
    },
    openingHours: {
      monday: "9:00 AM - 8:00 PM",
      tuesday: "9:00 AM - 8:00 PM",
      wednesday: "9:00 AM - 8:00 PM",
      thursday: "9:00 AM - 8:00 PM",
      friday: "9:00 AM - 8:00 PM",
      saturday: "9:00 AM - 8:00 PM",
      sunday: "9:00 AM - 8:00 PM",
      notes: "Summer hours. Winter hours vary. Weather dependent."
    },
    specialOffer: {
      description: "Early bird special: 20% off tours before 10am",
      validUntil: "2024-09-30"
    }
  },
  {
    id: 'hotel-1',
    name: "University Arms Hotel",
    description: "Landmark hotel with elegant rooms, some with views over Parker's Piece. Features Parkers Tavern restaurant and a library-themed bar.\n\nThis historic hotel, originally built in 1834, has recently undergone a comprehensive £80m transformation by architect John Simpson and interior designer Martin Brudnizki. The stunning results perfectly blend English classicism with contemporary comfort. Each room is filled with bespoke furniture, locally-sourced antiques, and custom-made beds. The literary-themed suites are named after famous Cambridge alumni, featuring carefully curated books and unique artwork celebrating their achievements.",
    category: 'stay',
    categories: ['stay', 'eating'],
    imageUrl: "https://images.unsplash.com/photo-1566073771259-6a8506099945?w=1200&auto=format&fit=crop&q=80",
    votes: 385,
    location: { lat: 52.2027, lng: 0.1230 },
    websiteUrl: "https://universityarms.com",
    bookingUrl: "https://universityarms.com/book",
    price: "From £200 per night",
    contact: {
      phone: "01223 606066",
      email: "info@universityarms.com",
      address: "Regent Street",
      postcode: "CB2 1AD"
    },
    specialOffer: {
      description: "Stay 3 nights, get 15% off",
      validUntil: "2024-12-31"
    }
  },
  {
    id: 'restaurant-1',
    name: "Trinity Restaurant",
    description: "Fine dining restaurant in the heart of Cambridge, offering modern British cuisine with a focus on local, seasonal ingredients.\n\nSet in a beautifully restored Victorian building, Trinity Restaurant combines historic charm with contemporary culinary excellence. Executive Chef Michael Carter, formerly of London's Michelin-starred establishments, creates innovative dishes that celebrate East Anglian produce. The restaurant works closely with local farmers, fishermen, and artisan producers to source the finest ingredients. The wine cellar houses over 300 carefully selected wines from both established and emerging vineyards.",
    category: 'eating',
    categories: ['eating'],
    imageUrl: "https://images.unsplash.com/photo-1592861956120-e524fc739696?w=1200&auto=format&fit=crop&q=80",
    votes: 298,
    location: { lat: 52.2060, lng: 0.1177 },
    websiteUrl: "https://www.trinity-restaurant.com",
    bookingUrl: "https://www.trinity-restaurant.com/book",
    price: "£££",
    contact: {
      phone: "01223 123456",
      email: "info@trinity-restaurant.com",
      address: "Trinity Street",
      postcode: "CB2 1TB"
    },
    specialOffer: {
      description: "Complimentary glass of champagne with tasting menu",
      validUntil: "2024-12-31"
    }
  },
  {
    id: 'college-1',
    name: "King's College",
    description: "Founded in 1441 by Henry VI, King's College is one of Cambridge's most iconic colleges, famous for its Gothic chapel, choir, and stunning riverside grounds.\n\nThe magnificent King's College Chapel took over a century to build and represents the finest example of English Gothic architecture. Its fan-vaulted ceiling is the largest in the world, and its stained-glass windows are considered masterpieces of medieval craftsmanship. The college's choir, formed of male choristers and undergraduate students, is renowned worldwide for its Christmas Eve service 'A Festival of Nine Lessons and Carols', broadcast globally by the BBC since 1928.",
    category: 'colleges',
    categories: ['colleges', 'history'],
    imageUrl: "https://images.unsplash.com/photo-1564463836146-4e30522c2984?w=1200&auto=format&fit=crop&q=80",
    votes: 456,
    location: { lat: 52.2044, lng: 0.1169 },
    websiteUrl: "https://www.kings.cam.ac.uk",
    bookingUrl: "https://www.kings.cam.ac.uk/visit/plan-your-visit",
    price: "£10 (Adults), £7 (Concessions)",
    contact: {
      phone: "01223 331100",
      email: "tourism@kings.cam.ac.uk",
      address: "King's Parade",
      postcode: "CB2 1ST"
    },
    specialOffer: {
      description: "Free entry for Cambridge residents with proof of address",
      validUntil: "2024-12-31"
    }
  },
  {
    id: 'cafe-1',
    name: "Fitzbillies",
    description: "Historic Cambridge café famous for its sticky Chelsea buns. A must-visit institution serving breakfast, lunch, and afternoon tea.\n\nEstablished in 1920, Fitzbillies has been a Cambridge institution for over a century. Their legendary Chelsea buns, made to a secret recipe that survived the shop's near-closure in 2011, are shipped worldwide. The café's Art Nouveau shop front and interior woodwork are original features from the 1920s. Stephen Fry once described their Chelsea buns as 'syrupy, sticky, dark and addictive.' The café now includes a coffee shop, restaurant, and cake shop, all maintaining the highest standards of traditional baking alongside contemporary cuisine.",
    category: 'coffee',
    categories: ['coffee', 'eating'],
    imageUrl: "https://images.unsplash.com/photo-1559925393-8be0ec4767c8?w=1200&auto=format&fit=crop&q=80",
    votes: 412,
    location: { lat: 52.2019, lng: 0.1189 },
    websiteUrl: "https://www.fitzbillies.com",
    bookingUrl: "https://www.fitzbillies.com/book",
    price: "££",
    contact: {
      phone: "01223 352500",
      email: "info@fitzbillies.com",
      address: "Trumpington Street",
      postcode: "CB2 1QY"
    },
    specialOffer: {
      description: "Buy 6 Chelsea buns, get 1 free",
      validUntil: "2024-12-31"
    }
  },
  {
    id: 'pub-1',
    name: "The Eagle",
    description: "Historic pub where Watson and Crick announced their discovery of DNA. Features RAF bar with ceiling graffiti from WWII pilots.\n\nOne of Cambridge's oldest pubs, dating back to the 14th century, The Eagle is steeped in scientific history. On February 28th, 1953, Francis Crick interrupted lunch here to announce they had 'discovered the secret of life' after decoding the structure of DNA. The RAF Bar features a unique ceiling covered in graffiti left by Allied airmen during WWII, who would burn their names and squadron numbers into the wood with candles and cigarette lighters. The pub maintains much of its original character, including wooden beams, open fires, and hidden courtyards.",
    category: 'pubs',
    categories: ['pubs', 'history', 'eating'],
    imageUrl: "https://images.unsplash.com/photo-1546726747-421c6d69c929?w=1200&auto=format&fit=crop&q=80",
    votes: 378,
    location: { lat: 52.2047, lng: 0.1182 },
    websiteUrl: "https://www.greeneking-pubs.co.uk/pubs/cambridgeshire/eagle/",
    price: "££",
    contact: {
      phone: "01223 505020",
      email: "eagle.cambridge@greeneking.co.uk",
      address: "Bene't Street",
      postcode: "CB2 3QN"
    }
  },
  {
    id: 'museum-1',
    name: "Fitzwilliam Museum",
    description: "The Fitzwilliam Museum houses world-class collections of works of art and antiquities spanning centuries and civilisations.\n\nFounded in 1816 through the bequest of Richard, VII Viscount Fitzwilliam of Merrion, the museum's magnificent neo-classical building is a landmark of the city. The collection includes masterpieces by Titian, Rubens, and Van Dyck, one of the world's finest collections of illuminated manuscripts, and an outstanding array of applied arts including armour, coins, and ceramics. The Egyptian galleries house one of the UK's most significant collections of antiquities, including a 4,000-year-old Egyptian sarcophagus that belonged to a priest named Nespawershefyt.",
    category: 'museums',
    categories: ['museums', 'art', 'history'],
    imageUrl: "https://images.unsplash.com/photo-1574236170880-c253c3f44dcb?w=1200&auto=format&fit=crop&q=80",
    votes: 389,
    location: { lat: 52.2000, lng: 0.1197 },
    websiteUrl: "https://www.fitzmuseum.cam.ac.uk",
    price: "Free",
    contact: {
      phone: "01223 332900",
      email: "fitzmuseum-enquiries@lists.cam.ac.uk",
      address: "Trumpington Street",
      postcode: "CB2 1RB"
    },
    openingHours: {
      monday: "Closed",
      tuesday: "10:00 AM - 5:00 PM",
      wednesday: "10:00 AM - 5:00 PM",
      thursday: "10:00 AM - 5:00 PM",
      friday: "10:00 AM - 5:00 PM",
      saturday: "10:00 AM - 5:00 PM",
      sunday: "12:00 PM - 5:00 PM"
    }
  },
  {
    id: 'market-1',
    name: "Cambridge Market Square",
    description: "Historic market square hosting daily stalls selling fresh produce, street food, crafts, and local goods.\n\nDating back to the Middle Ages, Cambridge Market Square has been at the heart of city life for over 800 years. The market was granted its royal charter in 1155 by King Henry II. Today, the square hosts over 100 stalls throughout the week, offering everything from local farm produce and artisan foods to vintage clothing and handmade crafts. The market is particularly known for its international street food vendors, second-hand books, and local artists selling their work. On Sundays, the square transforms into a vibrant arts, crafts, and local produce market.",
    category: 'shopping',
    categories: ['shopping', 'eating', 'outdoors'],
    imageUrl: "https://images.unsplash.com/photo-1513639776629-7b61b0ac49cb?w=1200&auto=format&fit=crop&q=80",
    votes: 367,
    location: { lat: 52.2053, lng: 0.1192 },
    websiteUrl: "https://www.cambridge.gov.uk/markets",
    contact: {
      address: "Market Square",
      postcode: "CB2 3QJ"
    },
    openingHours: {
      monday: "10:00 AM - 4:00 PM",
      tuesday: "10:00 AM - 4:00 PM",
      wednesday: "10:00 AM - 4:00 PM",
      thursday: "10:00 AM - 4:00 PM",
      friday: "10:00 AM - 4:00 PM",
      saturday: "10:00 AM - 4:00 PM",
      sunday: "10:00 AM - 4:00 PM"
    }
  },
  {
    id: 'botanic-1',
    name: "Cambridge University Botanic Garden",
    description: "40 acres of beautiful gardens and glasshouses featuring plants from around the world. A peaceful haven in the city center.\n\nEstablished in 1846 by John Stevens Henslow, mentor to Charles Darwin, the garden plays a crucial role in botanical research and conservation. The collection includes over 8,000 plant species, arranged in themed areas including a scented garden, winter garden, and rock gardens. The historic glasshouses showcase plants from diverse climatic zones, including one of the UK's most comprehensive collections of orchids. The garden has contributed to numerous scientific discoveries and continues to support research into plant sciences, conservation, and climate change.",
    category: 'outdoors',
    categories: ['outdoors', 'wellbeing'],
    imageUrl: "https://images.unsplash.com/photo-1585320806297-9794b3e4eeae?w=1200&auto=format&fit=crop&q=80",
    votes: 401,
    location: { lat: 52.1937, lng: 0.1287 },
    websiteUrl: "https://www.botanic.cam.ac.uk",
    price: "£7 (Adults), £6.30 (Concessions)",
    contact: {
      phone: "01223 336265",
      email: "enquiries@botanic.cam.ac.uk",
      address: "1 Brookside",
      postcode: "CB2 1JE"
    },
    specialOffer: {
      description: "Annual membership: 2 months free when paying by direct debit",
      validUntil: "2024-12-31"
    }
  },
  {
    id: 'yoga-1',
    name: "Camyoga",
    description: "Premium yoga studio offering a variety of classes from beginner to advanced, including hot yoga and meditation.\n\nFounded by leading yoga practitioners, Camyoga has become Cambridge's premier destination for yoga and wellness. The studio features state-of-the-art heating and ventilation systems for hot yoga, specially designed acoustic treatment for optimal sound quality during meditation, and eco-friendly bamboo flooring. Their team of international instructors brings diverse expertise in styles including Ashtanga, Vinyasa, Yin, and therapeutic yoga. The studio also offers workshops with visiting masters and teacher training programs accredited by Yoga Alliance.",
    category: 'wellbeing',
    categories: ['wellbeing', 'fitness'],
    imageUrl: "https://images.unsplash.com/photo-1588286840104-8957b019727f?w=1200&auto=format&fit=crop&q=80",
    votes: 289,
    location: { lat: 52.2151, lng: 0.1346 },
    websiteUrl: "https://www.camyoga.co.uk",
    bookingUrl: "https://www.camyoga.co.uk/book",
    price: "From £12 per class",
    contact: {
      phone: "01223 847930",
      email: "info@camyoga.co.uk",
      address: "Mitcham's Corner",
      postcode: "CB4 3EF"
    },
    specialOffer: {
      description: "First class free for new students",
      validUntil: "2024-12-31"
    }
  },
  {
    id: 'climbing-1',
    name: "Rainbow Rocket Climbing",
    description: "Modern climbing center with bouldering walls suitable for all abilities. Includes training area and café.\n\nRainbow Rocket features over 300 square meters of climbing surface with routes graded from beginner to expert. The center uses an innovative rotating wall system that allows routes to be changed frequently, ensuring fresh challenges for regular climbers. The facility includes campus boards, fingerboards, and conditioning areas for targeted training. Their experienced instructors offer courses in technique, movement, and competition preparation. The on-site café serves locally-sourced healthy food and specialty coffee, making it a social hub for Cambridge's climbing community.",
    category: 'sport',
    categories: ['sport', 'fitness'],
    imageUrl: "https://images.unsplash.com/photo-1522163182402-834f871fd851?w=1200&auto=format&fit=crop&q=80",
    votes: 322,
    location: { lat: 52.2151, lng: 0.1346 },
    websiteUrl: "https://www.rainbowrocket.com",
    price: "£12 entry, £5 shoe hire",
    contact: {
      phone: "01223 366021",
      email: "info@rainbowrocket.com",
      address: "Unit 7, Nuffield Close",
      postcode: "CB4 1SS"
    },
    specialOffer: {
      description: "Student discount: 20% off entry Monday-Friday",
      validUntil: "2024-12-31"
    }
  }
];