export const categoryColors = {
  top10: {
    bg: 'bg-yellow-100',
    text: 'text-yellow-800',
    border: 'border-yellow-300'
  },
  eating: {
    bg: 'bg-orange-100',
    text: 'text-orange-800',
    border: 'border-orange-300'
  },
  colleges: {
    bg: 'bg-sky-100',
    text: 'text-sky-800',
    border: 'border-sky-300'
  },
  pubs: {
    bg: 'bg-amber-100',
    text: 'text-amber-800',
    border: 'border-amber-300'
  },
  history: {
    bg: 'bg-stone-100',
    text: 'text-stone-800',
    border: 'border-stone-300'
  },
  outdoors: {
    bg: 'bg-emerald-100',
    text: 'text-emerald-800',
    border: 'border-emerald-300'
  },
  shopping: {
    bg: 'bg-pink-100',
    text: 'text-pink-800',
    border: 'border-pink-300'
  },
  events: {
    bg: 'bg-violet-100',
    text: 'text-violet-800',
    border: 'border-violet-300'
  },
  tours: {
    bg: 'bg-blue-100',
    text: 'text-blue-800',
    border: 'border-blue-300'
  },
  games: {
    bg: 'bg-purple-100',
    text: 'text-purple-800',
    border: 'border-purple-300'
  },
  stay: {
    bg: 'bg-indigo-100',
    text: 'text-indigo-800',
    border: 'border-indigo-300'
  },
  entertainment: {
    bg: 'bg-fuchsia-100',
    text: 'text-fuchsia-800',
    border: 'border-fuchsia-300'
  },
  talks: {
    bg: 'bg-rose-100',
    text: 'text-rose-800',
    border: 'border-rose-300'
  },
  workshops: {
    bg: 'bg-yellow-100',
    text: 'text-yellow-800',
    border: 'border-yellow-300'
  },
  museums: {
    bg: 'bg-slate-100',
    text: 'text-slate-800',
    border: 'border-slate-300'
  },
  classes: {
    bg: 'bg-teal-100',
    text: 'text-teal-800',
    border: 'border-teal-300'
  },
  coffee: {
    bg: 'bg-brown-100',
    text: 'text-brown-800',
    border: 'border-brown-300'
  },
  cam: {
    bg: 'bg-cyan-100',
    text: 'text-cyan-800',
    border: 'border-cyan-300'
  },
  sport: {
    bg: 'bg-red-100',
    text: 'text-red-800',
    border: 'border-red-300'
  },
  fitness: {
    bg: 'bg-lime-100',
    text: 'text-lime-800',
    border: 'border-lime-300'
  },
  wellbeing: {
    bg: 'bg-green-100',
    text: 'text-green-800',
    border: 'border-green-300'
  },
  othersyde: {
    bg: 'bg-purple-100',
    text: 'text-purple-800',
    border: 'border-purple-300'
  },
  art: {
    bg: 'bg-rose-100',
    text: 'text-rose-800',
    border: 'border-rose-300'
  }
} as const;