import React, { useState } from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { X, HeartCrack, Gift } from 'lucide-react';
import { useAppState } from '../lib/state';
import { useNavigate } from 'react-router-dom';

interface DeleteAccountDialogProps {
  onClose: () => void;
  onConfirm: () => void;
}

export function DeleteAccountDialog({ onClose, onConfirm }: DeleteAccountDialogProps) {
  const [showGoodbye, setShowGoodbye] = useState(false);
  const { awardRetentionBonus, deleteAccount } = useAppState();
  const navigate = useNavigate();

  const handleStaying = () => {
    const awarded = awardRetentionBonus();
    if (awarded) {
      // Show retention bonus overlay
      onClose();
    } else {
      // Just close if bonus was already awarded before
      onClose();
    }
  };

  const handleDelete = async () => {
    setShowGoodbye(true);
    try {
      await deleteAccount();
      setTimeout(() => {
        onConfirm();
        navigate('/');
      }, 3000);
    } catch (error) {
      console.error('Failed to delete account:', error);
    }
  };

  if (showGoodbye) {
    return (
      <OverlayWrapper>
        <div className="fixed inset-4 md:relative md:inset-auto bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl shadow-xl overflow-hidden max-w-md mx-auto">
          <div className="p-6">
            <div className="text-center space-y-4">
              <HeartCrack className="w-16 h-16 mx-auto text-red-400" />
              <h3 className="text-2xl font-bold text-white">Goodbye! 👋</h3>
              <p className="text-white/90">
                Thank you for using mycam.city. We hope to see you again in the future!
              </p>
              <p className="text-white/70 text-sm mt-4">
                Your account will be deleted in a few seconds...
              </p>
            </div>
          </div>
        </div>
      </OverlayWrapper>
    );
  }

  return (
    <OverlayWrapper>
      <div className="fixed inset-4 md:relative md:inset-auto bg-gradient-to-br from-red-900 to-purple-900 rounded-xl shadow-xl overflow-hidden max-w-md mx-auto">
        <div className="p-6">
          {/* Header */}
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-white">Delete Account</h2>
            <button 
              onClick={onClose}
              className="p-2 text-white hover:bg-white/10 rounded-lg"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {/* Content */}
          <div className="text-center space-y-4">
            <HeartCrack className="w-16 h-16 mx-auto text-red-400" />
            <h3 className="text-2xl font-bold text-white">We'll Miss You! 💔</h3>
            <p className="text-white/90">
              Are you sure you want to delete your account? This action cannot be undone, 
              and we'll be really sad to see you go!
            </p>
          </div>

          {/* Buttons */}
          <div className="flex gap-3 mt-8">
            <button
              onClick={handleDelete}
              className="flex-1 px-6 py-3 bg-gray-600 hover:bg-gray-700 text-white rounded-lg 
                transition-colors font-medium"
            >
              <span className="block mb-1">Delete Account</span>
              <span className="block text-2xl">😢</span>
            </button>
            <button
              onClick={handleStaying}
              className="flex-1 px-6 py-3 bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg 
                transition-colors font-medium"
            >
              <span className="block mb-1">Keep Account</span>
              <span className="block text-2xl">😊</span>
            </button>
          </div>
        </div>
      </div>
    </OverlayWrapper>
  );
}