import React, { useState } from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { X, User } from 'lucide-react';
import { AboutMeOverlay } from './AboutMeOverlay';
import { EditProfileOverlay } from './EditProfileOverlay';
import { DeleteAccountDialog } from './DeleteAccountDialog';
import { useAppState } from '../lib/state';
import { useNavigate } from 'react-router-dom';

interface AccountOverlayProps {
  onClose: () => void;
  userProfile?: { name: string };
}

export function AccountOverlay({ onClose, userProfile }: AccountOverlayProps) {
  const [showAboutMe, setShowAboutMe] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const navigate = useNavigate();

  const handleDeleteAccount = () => {
    setShowDeleteConfirm(false);
    onClose();
  };

  return (
    <>
      <OverlayWrapper>
        <div className="fixed inset-4 bg-gradient-to-br from-indigo-900 via-purple-900 to-[#1A1F2B] rounded-xl shadow-xl overflow-hidden">
          <div className="h-full flex flex-col">
            <div className="flex justify-between items-center p-6 border-b border-white/10">
              <h2 className="text-xl font-bold text-white">Account Details</h2>
              <button 
                onClick={onClose}
                className="p-2 hover:bg-white/10 rounded-lg text-white"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <div className="flex-1 overflow-y-auto p-6">
              <div className="space-y-6">
                {/* Account Details */}
                <div className="bg-white/10 rounded-lg p-4">
                  <h3 className="text-lg font-medium text-white mb-2">Account Details</h3>
                  <p className="text-white/70">Name: {userProfile?.name}</p>
                </div>

                {/* Account Management Options */}
                <div className="space-y-2">
                  <button 
                    onClick={() => setShowAboutMe(true)}
                    className="w-full px-4 py-3 text-left text-white hover:bg-white/10 rounded-lg transition-colors flex items-center gap-3"
                  >
                    <User className="w-5 h-5" />
                    About Me
                  </button>
                  <button 
                    onClick={() => setShowEditProfile(true)}
                    className="w-full px-4 py-3 text-left text-white hover:bg-white/10 rounded-lg transition-colors"
                  >
                    Edit Profile
                  </button>
                  <button className="w-full px-4 py-3 text-left text-white hover:bg-white/10 rounded-lg transition-colors">
                    Change Password
                  </button>
                  <button className="w-full px-4 py-3 text-left text-white hover:bg-white/10 rounded-lg transition-colors">
                    Notification Settings
                  </button>
                  <button className="w-full px-4 py-3 text-left text-white hover:bg-white/10 rounded-lg transition-colors">
                    Privacy Settings
                  </button>
                </div>

                {/* Delete Account Section */}
                <div className="pt-4 mt-4 border-t border-white/10">
                  <button 
                    onClick={() => setShowDeleteConfirm(true)}
                    className="w-full px-4 py-3 text-left text-red-400 hover:bg-red-500/10 rounded-lg transition-colors"
                  >
                    Delete My Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OverlayWrapper>

      {/* About Me Overlay */}
      {showAboutMe && (
        <AboutMeOverlay
          onClose={() => setShowAboutMe(false)}
          userProfile={userProfile}
        />
      )}

      {/* Edit Profile Overlay */}
      {showEditProfile && (
        <EditProfileOverlay
          onClose={() => setShowEditProfile(false)}
        />
      )}

      {/* Delete Account Confirmation */}
      {showDeleteConfirm && (
        <DeleteAccountDialog
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={handleDeleteAccount}
        />
      )}
    </>
  );
}