import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey);

export async function uploadImage(file: File, placeId: string): Promise<string> {
  try {
    // Validate file type and size
    if (!file.type.startsWith('image/')) {
      throw new Error('Only image files are allowed');
    }
    
    if (file.size > 5 * 1024 * 1024) { // 5MB limit
      throw new Error('File size must be less than 5MB');
    }

    // Create a unique file name
    const fileExt = file.name.split('.').pop();
    const fileName = `${placeId}/${crypto.randomUUID()}.${fileExt}`;

    // Upload file to Supabase Storage
    const { data: uploadData, error: uploadError } = await supabase.storage
      .from('place-images')
      .upload(fileName, file, {
        cacheControl: '3600',
        upsert: false,
        contentType: file.type
      });

    if (uploadError) throw uploadError;

    // Get the public URL
    const { data: { publicUrl } } = supabase.storage
      .from('place-images')
      .getPublicUrl(uploadData.path);

    // Store the image reference in the database
    const { error: dbError } = await supabase
      .from('place_images')
      .insert({
        place_id: placeId,
        url: publicUrl,
        is_default: false
      });

    if (dbError) throw dbError;

    return publicUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
}

export async function getImagesForPlace(placeId: string): Promise<string[]> {
  try {
    const { data, error } = await supabase
      .from('place_images')
      .select('url')
      .eq('place_id', placeId)
      .order('created_at', { ascending: false });

    if (error) throw error;

    return data?.map(img => img.url) || [];
  } catch (error) {
    console.error('Error fetching images:', error);
    return [];
  }
}

export async function deleteImage(imageUrl: string): Promise<void> {
  try {
    // Extract the file path from the URL
    const urlParts = imageUrl.split('/');
    const fileName = `${urlParts[urlParts.length - 2]}/${urlParts[urlParts.length - 1]}`;

    // Delete from storage
    const { error: storageError } = await supabase.storage
      .from('place-images')
      .remove([fileName]);

    if (storageError) throw storageError;

    // Delete from database
    const { error: dbError } = await supabase
      .from('place_images')
      .delete()
      .eq('url', imageUrl);

    if (dbError) throw dbError;
  } catch (error) {
    console.error('Error deleting image:', error);
    throw error;
  }
}

export async function setDefaultImage(placeId: string, imageUrl: string): Promise<void> {
  const { error: updateError } = await supabase
    .from('place_images')
    .update({ is_default: false })
    .eq('place_id', placeId)
    .eq('is_default', true);

  if (updateError) throw updateError;

  const { error: setDefaultError } = await supabase
    .from('place_images')
    .update({ is_default: true })
    .eq('place_id', placeId)
    .eq('url', imageUrl);

  if (setDefaultError) throw setDefaultError;
}