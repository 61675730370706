import React, { useState } from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { X, Star, Calendar, ChevronLeft, ChevronRight } from 'lucide-react';
import { useAppState } from '../lib/state';
import { PLACES } from '../data/places';
import { categoryColors } from '../utils/categoryColors';
import { categoryIcons } from '../utils/categoryIcons';

interface UserReviewsOverlayProps {
  onClose: () => void;
  onPlaceClick: (id: string) => void;
  placeId?: string; // Optional - if provided, only show reviews for this place
}

const REVIEWS_PER_PAGE = 10;

export function UserReviewsOverlay({ onClose, onPlaceClick, placeId }: UserReviewsOverlayProps) {
  const { getAllReviews } = useAppState();
  const [currentPage, setCurrentPage] = useState(1);

  // Get all reviews using the getAllReviews function
  const allReviews = getAllReviews(placeId);
  const userReviews = allReviews.map(review => ({
    placeId: placeId || review.id,
    ...review
  }));

  const totalPages = Math.ceil(userReviews.length / REVIEWS_PER_PAGE);
  const paginatedReviews = userReviews.slice(
    (currentPage - 1) * REVIEWS_PER_PAGE,
    currentPage * REVIEWS_PER_PAGE
  );

  return (
    <OverlayWrapper>
      <div className="fixed inset-4 bg-white rounded-xl shadow-xl overflow-hidden">
        {/* Header */}
        <div className="bg-gradient-to-r from-indigo-600 to-purple-600 p-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <Star className="w-8 h-8 text-yellow-400" />
              <h2 className="text-xl font-bold text-white">Reviews</h2>
            </div>
            <button
              onClick={onClose}
              className="p-2 text-white hover:bg-white/10 rounded-lg"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
          <p className="text-white/80 mt-2">
            {placeId ? 'Reviews for this place' : 'Reviews of places in Cambridge'}
          </p>
        </div>

        {/* Reviews List */}
        <div className="overflow-y-auto" style={{ height: 'calc(100% - 180px)' }}>
          {paginatedReviews.length > 0 ? (
            paginatedReviews.map((review, index) => {
              const place = PLACES.find(p => p.id === (placeId || review.placeId));
              if (!place) return null;

              const Icon = categoryIcons[place.category];
              const colors = categoryColors[place.category];

              return (
                <div
                  key={`${review.placeId}-${index}`}
                  className="p-4 border-b hover:bg-gray-50 cursor-pointer"
                  onClick={() => onPlaceClick(placeId || review.placeId)}
                >
                  <div className="flex gap-4">
                    {/* Place Image */}
                    <div className="w-24 h-24 rounded-lg overflow-hidden flex-shrink-0">
                      <img
                        src={place.imageUrl}
                        alt={place.name}
                        className="w-full h-full object-cover"
                      />
                    </div>

                    {/* Review Content */}
                    <div className="flex-1">
                      <div className="flex items-start justify-between">
                        <div>
                          <h3 className="font-semibold text-gray-900">{place.name}</h3>
                          <div className={`inline-flex items-center gap-1 px-2 py-1 rounded-full text-sm ${colors.bg} ${colors.text} mt-1`}>
                            <Icon className="w-4 h-4" />
                            <span>{place.category}</span>
                          </div>
                        </div>
                        <div className="flex items-center gap-1">
                          {[...Array(5)].map((_, i) => (
                            <Star
                              key={i}
                              className={`w-4 h-4 ${
                                i < review.rating
                                  ? 'text-yellow-400 fill-current'
                                  : 'text-gray-300'
                              }`}
                            />
                          ))}
                        </div>
                      </div>

                      <p className="text-gray-600 mt-2 line-clamp-2">{review.comment}</p>

                      <div className="flex items-center gap-2 mt-2 text-sm text-gray-500">
                        <Calendar className="w-4 h-4" />
                        <span>{new Date(review.date).toLocaleDateString()}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="p-8 text-center">
              <div className="max-w-sm mx-auto space-y-4">
                <div className="w-24 h-24 mx-auto bg-gray-100 rounded-full flex items-center justify-center">
                  <Star className="w-12 h-12 text-gray-400" />
                </div>
                <div className="space-y-2">
                  <p className="text-xl font-semibold text-gray-900">No reviews yet</p>
                  <p className="text-gray-500">
                    Be the first to share your experience!
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="absolute bottom-0 left-0 right-0 p-4 border-t bg-white">
            <div className="flex items-center justify-between">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
              <span className="text-sm text-gray-600">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        )}
      </div>
    </OverlayWrapper>
  );
}