import React, { useState, useEffect } from 'react';
import { Star, Save } from 'lucide-react';
import type { Place, ScoringCriteria, PlaceScore } from '../../types';
import { getScoringCriteria, getPlaceScores, scorePlaceForCriteria } from '../../lib/scoring';

interface PlaceScoringProps {
  place: Place;
  onScoreUpdate?: () => void;
}

export function PlaceScoring({ place, onScoreUpdate }: PlaceScoringProps) {
  const [criteria, setCriteria] = useState<ScoringCriteria[]>([]);
  const [scores, setScores] = useState<Record<string, number>>({});
  const [notes, setNotes] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadData();
  }, [place.id]);

  async function loadData() {
    try {
      const [criteriaData, scoresData] = await Promise.all([
        getScoringCriteria(),
        getPlaceScores(place.id)
      ]);

      setCriteria(criteriaData);
      
      // Initialize scores and notes from existing data
      const scoreMap: Record<string, number> = {};
      const noteMap: Record<string, string> = {};
      
      scoresData.forEach(score => {
        scoreMap[score.criteriaId] = score.score;
        if (score.notes) noteMap[score.criteriaId] = score.notes;
      });

      setScores(scoreMap);
      setNotes(noteMap);
      setLoading(false);
    } catch (err) {
      setError('Failed to load scoring data');
      setLoading(false);
    }
  }

  const handleScoreChange = (criteriaId: string, score: number) => {
    setScores(prev => ({ ...prev, [criteriaId]: score }));
  };

  const handleNotesChange = (criteriaId: string, note: string) => {
    setNotes(prev => ({ ...prev, [criteriaId]: note }));
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      await Promise.all(
        Object.entries(scores).map(([criteriaId, score]) =>
          scorePlaceForCriteria(
            place.id,
            criteriaId,
            score,
            notes[criteriaId]
          )
        )
      );
      onScoreUpdate?.();
    } catch (err) {
      setError('Failed to save scores');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="p-6 text-center">
        <div className="w-8 h-8 border-4 border-indigo-600 border-t-transparent rounded-full animate-spin mx-auto" />
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-gray-900">Score: {place.name}</h2>
        <button
          onClick={handleSave}
          disabled={saving}
          className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 
            transition-colors flex items-center gap-2 disabled:opacity-50"
        >
          <Save className="w-4 h-4" />
          Save Scores
        </button>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-600">
          {error}
        </div>
      )}

      <div className="space-y-6">
        {criteria.map(criterion => (
          <div key={criterion.id} className="bg-gray-50 p-4 rounded-lg">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="font-medium text-gray-900">{criterion.name}</h3>
                <p className="text-sm text-gray-600">{criterion.description}</p>
              </div>
              <div className="flex items-center gap-1">
                {[...Array(criterion.maxScore)].map((_, i) => (
                  <button
                    key={i}
                    onClick={() => handleScoreChange(criterion.id, i + 1)}
                    className={`p-1 rounded-full hover:bg-gray-200 transition-colors
                      ${scores[criterion.id] >= i + 1 ? 'text-yellow-400' : 'text-gray-300'}`}
                  >
                    <Star className="w-5 h-5 fill-current" />
                  </button>
                ))}
              </div>
            </div>

            <textarea
              value={notes[criterion.id] || ''}
              onChange={e => handleNotesChange(criterion.id, e.target.value)}
              placeholder="Add notes about this score..."
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 text-sm"
              rows={2}
            />
          </div>
        ))}
      </div>
    </div>
  );
}