import React, { useState, useEffect } from 'react';
import { Calendar, DollarSign, Clock } from 'lucide-react';
import type { BidSlot } from '../../types';
import { getCurrentBids } from '../../lib/scoring';

export function BiddingManager() {
  const [bids, setBids] = useState<BidSlot[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadBids();
  }, []);

  async function loadBids() {
    try {
      const data = await getCurrentBids();
      setBids(data);
      setLoading(false);
    } catch (err) {
      setError('Failed to load current bids');
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="p-6 text-center">
        <div className="w-8 h-8 border-4 border-indigo-600 border-t-transparent rounded-full animate-spin mx-auto" />
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold text-gray-900 mb-6">Current Bids</h2>

      {error && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-600">
          {error}
        </div>
      )}

      <div className="space-y-4">
        {bids.map(bid => (
          <div
            key={bid.id}
            className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg"
          >
            <div className="w-16 h-16 bg-gray-200 rounded-lg flex items-center justify-center text-2xl font-bold text-gray-600">
              #{bid.position}
            </div>

            <div className="flex-1">
              <h3 className="font-medium text-gray-900">
                {bid.place?.name || 'Unknown Place'}
              </h3>
              
              <div className="flex gap-4 mt-1 text-sm text-gray-500">
                <div className="flex items-center gap-1">
                  <DollarSign className="w-4 h-4" />
                  <span>£{bid.amount.toFixed(2)}</span>
                </div>
                
                <div className="flex items-center gap-1">
                  <Calendar className="w-4 h-4" />
                  <span>{new Date(bid.startDate).toLocaleDateString()}</span>
                </div>
                
                <div className="flex items-center gap-1">
                  <Clock className="w-4 h-4" />
                  <span>
                    {Math.ceil(
                      (new Date(bid.endDate).getTime() - new Date().getTime()) / 
                      (1000 * 60 * 60 * 24)
                    )} days left
                  </span>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2">
              <span className={`px-3 py-1 rounded-full text-sm font-medium
                ${bid.status === 'active' ? 'bg-green-100 text-green-800' : 
                  bid.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                  'bg-gray-100 text-gray-800'}`}
              >
                {bid.status}
              </span>
            </div>
          </div>
        ))}

        {bids.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No active bids at the moment
          </div>
        )}
      </div>
    </div>
  );
}