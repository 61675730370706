import React from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { X, Star, Calendar, User } from 'lucide-react';
import { useAppState } from '../lib/state';
import type { Place } from '../types';

interface RatingsOverlayProps {
  place: Place;
  onClose: () => void;
}

export function RatingsOverlay({ place, onClose }: RatingsOverlayProps) {
  const { getAllReviews } = useAppState();
  const reviews = getAllReviews(place.id);

  const averageRating = reviews.length > 0
    ? reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length
    : 0;

  return (
    <OverlayWrapper>
      <div className="fixed inset-4 bg-white rounded-xl shadow-xl overflow-hidden">
        {/* Header */}
        <div className="bg-gradient-to-r from-yellow-500 to-amber-500 p-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <Star className="w-8 h-8 text-white" />
              <div>
                <h2 className="text-xl font-bold text-white">Ratings & Reviews</h2>
                <p className="text-white/90">{place.name}</p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 text-white hover:bg-white/10 rounded-lg"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="overflow-y-auto" style={{ height: 'calc(100% - 116px)' }}>
          {/* Average Rating */}
          <div className="p-6 border-b">
            <div className="flex items-center justify-between">
              <div>
                <div className="text-3xl font-bold text-gray-900">
                  {averageRating.toFixed(1)}
                </div>
                <div className="flex items-center gap-1 mt-1">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Star
                      key={star}
                      className={`w-5 h-5 ${
                        star <= averageRating
                          ? 'text-yellow-400 fill-current'
                          : 'text-gray-300'
                      }`}
                    />
                  ))}
                </div>
              </div>
              <div className="text-right">
                <div className="text-sm text-gray-500">Based on</div>
                <div className="text-2xl font-bold text-gray-900">
                  {reviews.length}
                </div>
                <div className="text-sm text-gray-500">
                  {reviews.length === 1 ? 'review' : 'reviews'}
                </div>
              </div>
            </div>
          </div>

          {/* Reviews List */}
          <div className="divide-y">
            {reviews.length > 0 ? (
              reviews.map((review, index) => (
                <div key={index} className="p-6">
                  <div className="flex items-center gap-3 mb-3">
                    <div className="w-10 h-10 rounded-full bg-gradient-to-br from-indigo-400 to-purple-400 flex items-center justify-center text-white font-medium">
                      {review.placeId.charAt(0).toUpperCase()}
                    </div>
                    <div>
                      <div className="font-medium text-gray-900">
                        {review.placeId}
                      </div>
                      <div className="text-sm text-gray-500">
                        {new Date(review.date).toLocaleDateString()}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-1 mb-3">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star
                        key={star}
                        className={`w-5 h-5 ${
                          star <= review.rating
                            ? 'text-yellow-400 fill-current'
                            : 'text-gray-300'
                        }`}
                      />
                    ))}
                  </div>

                  <p className="text-gray-700">{review.comment}</p>
                </div>
              ))
            ) : (
              <div className="p-8 text-center">
                <Star className="w-12 h-12 mx-auto text-gray-400 mb-4" />
                <p className="text-gray-900 font-medium">No reviews yet</p>
                <p className="text-gray-500 mt-1">
                  Be the first to review this place!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </OverlayWrapper>
  );
}