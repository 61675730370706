import React from 'react';
import { Check, AlertCircle } from 'lucide-react';

interface ServiceTiersProps {
  placeId: string;
}

export function ServiceTiers({ placeId }: ServiceTiersProps) {
  return (
    <div className="space-y-8">
      {/* Free Tier */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Free Listing</h3>
          <p className="text-gray-600 mb-4">
            Get started with a basic listing to showcase your venue to Cambridge visitors. Perfect for new businesses looking to establish their online presence.
          </p>
          <div className="space-y-3">
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Basic venue listing</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Single category listing</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Standard search placement</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Basic analytics</span>
            </div>
          </div>
          <div className="mt-6">
            <span className="text-2xl font-bold text-gray-900">Free</span>
            <span className="text-gray-500">/forever</span>
          </div>
        </div>
      </div>

      {/* Enhanced Listing */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Enhanced Listing</h3>
          <p className="text-gray-600 mb-4">
            Upgrade your presence with additional features designed to help you reach more visitors and showcase your venue in the best light.
          </p>
          <div className="space-y-3">
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Everything in Free, plus:</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Multiple category listings</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Website and booking links</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Special offers feature</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Multi-language support</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Advanced analytics</span>
            </div>
          </div>
          <div className="mt-6">
            <span className="text-2xl font-bold text-gray-900">£29</span>
            <span className="text-gray-500">/month</span>
          </div>
        </div>
      </div>

      {/* Premium Features */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Premium Features</h3>
          <p className="text-gray-600 mb-4">
            Take full advantage of our platform with premium features designed to maximize your venue's visibility and engagement.
          </p>
          <div className="space-y-3">
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Everything in Enhanced, plus:</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Featured position bidding</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Push notifications</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Priority support</span>
            </div>
            <div className="flex items-start gap-2">
              <Check className="w-5 h-5 text-green-500 mt-0.5" />
              <span className="text-gray-700">Social media promotion</span>
            </div>
          </div>
          <div className="mt-6">
            <span className="text-2xl font-bold text-gray-900">£79</span>
            <span className="text-gray-500">/month</span>
          </div>
        </div>
      </div>

      {/* Information Box */}
      <div className="bg-blue-50 p-6 rounded-lg">
        <div className="flex items-start gap-3">
          <AlertCircle className="w-5 h-5 text-blue-600 flex-shrink-0 mt-1" />
          <div>
            <h4 className="font-semibold text-blue-900 mb-2">Why upgrade your listing?</h4>
            <ul className="space-y-2 text-blue-800">
              <li>• Reach more visitors with enhanced visibility</li>
              <li>• Showcase special offers and events</li>
              <li>• Get detailed insights about your audience</li>
              <li>• Stand out from competitors</li>
              <li>• Drive more bookings and engagement</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}