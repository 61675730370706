import React, { useEffect, useRef } from 'react';
import { MessageCircle, Mail, Copy, Trophy } from 'lucide-react';
import { useAppState } from '../lib/state';
import { createReferralCode } from '../lib/referral';
import { v4 as uuidv4 } from 'uuid';

interface ShareMenuProps {
  placeId: string;
  placeName: string;
  onClose: () => void;
}

export function ShareMenu({ placeId, placeName, onClose }: ShareMenuProps) {
  const { toggleShare, userProfile } = useAppState();
  const menuRef = useRef<HTMLDivElement>(null);

  const generateShareUrl = () => {
    const shareId = uuidv4();
    const referralCode = createReferralCode(
      userProfile?.id || 'anonymous',
      placeId,
      shareId
    );
    return `${window.location.origin}/?ref=${referralCode}`;
  };

  const shareUrl = generateShareUrl();
  const shareText = `Check out ${placeName} on mycam.city!`;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const handleShare = async (platform: 'whatsapp' | 'email' | 'copy') => {
    let url = '';
    
    switch (platform) {
      case 'whatsapp':
        url = `https://wa.me/?text=${encodeURIComponent(`${shareText} ${shareUrl}`)}`;
        break;
      case 'email':
        url = `mailto:?subject=${encodeURIComponent(`Check out ${placeName}`)}&body=${encodeURIComponent(`${shareText} ${shareUrl}`)}`;
        break;
      case 'copy':
        try {
          await navigator.clipboard.writeText(shareUrl);
        } catch (err) {
          console.error('Failed to copy:', err);
        }
        break;
    }

    if (url) {
      window.open(url, '_blank');
    }

    // Record the share
    toggleShare(placeId, platform);
    if (platform !== 'copy') {
      onClose();
    }
  };

  return (
    <div 
      ref={menuRef}
      className="absolute top-full right-0 mt-2 bg-black/90 backdrop-blur-sm rounded-lg shadow-xl p-2 
        min-w-[280px] animate-fadeIn transform-gpu z-50"
      style={{ 
        animation: 'fadeIn 0.2s ease-out',
        transformOrigin: 'top right' 
      }}
    >
      {/* Points Message */}
      <div className="px-4 py-3 mb-1 border-b border-white/10">
        <div className="flex items-center gap-2 text-yellow-400 mb-1">
          <Trophy className="w-4 h-4" />
          <span className="text-sm font-medium">Share your discovery!</span>
        </div>
        <p className="text-xs text-white/80">
          Share {placeName} with friends and family to add more points to your score
        </p>
      </div>

      <div className="space-y-0.5">
        <div
          onClick={() => handleShare('whatsapp')}
          className="flex items-center gap-3 w-full px-4 py-2.5 hover:bg-white/10 rounded-lg 
            transition-colors text-white/90 hover:text-white cursor-pointer"
        >
          <MessageCircle className="w-5 h-5 text-[#25D366]" />
          <span className="text-sm font-medium">Share on WhatsApp</span>
        </div>

        <div
          onClick={() => handleShare('email')}
          className="flex items-center gap-3 w-full px-4 py-2.5 hover:bg-white/10 rounded-lg 
            transition-colors text-white/90 hover:text-white cursor-pointer"
        >
          <Mail className="w-5 h-5 text-white/90" />
          <span className="text-sm font-medium">Share via Email</span>
        </div>

        <div
          onClick={() => handleShare('copy')}
          className="flex items-center gap-3 w-full px-4 py-2.5 hover:bg-white/10 rounded-lg 
            transition-colors text-white/90 hover:text-white cursor-pointer"
        >
          <Copy className="w-5 h-5 text-white/90" />
          <span className="text-sm font-medium">Copy Link</span>
        </div>
      </div>
    </div>
  );
}