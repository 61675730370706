import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { X, MapPin, Clock, Star, Link, Gift, Phone, Mail, Calendar, Navigation, Info, CheckCircle, Share2, Map } from 'lucide-react';
import { OverlayWrapper } from './OverlayWrapper';
import { ReviewForm } from './ReviewForm';
import { ShareMenu } from './ShareMenu';
import type { Place } from '../types';
import { categoryColors } from '../utils/categoryColors';
import { categoryIcons } from '../utils/categoryIcons';
import { useAppState } from '../lib/state';
import { getDistanceFromUser } from '../utils/distance';

interface VenueDetailsProps {
  place: Place;
  onClose: () => void;
  initialTab?: string;
  userLocation?: { lat: number; lng: number } | null;
}

export function VenueDetails({ place, onClose, initialTab = 'info', userLocation }: VenueDetailsProps) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<'info' | 'location' | 'reviews' | 'offers' | 'visited'>(
    initialTab as 'info' | 'location' | 'reviews' | 'offers' | 'visited'
  );
  const [showShareMenu, setShowShareMenu] = useState(false);

  const { 
    isSignedIn,
    visitedPlaces,
    toggleVisited,
    getAllReviews
  } = useAppState();

  const placeReviews = getAllReviews(place.id);
  const isVisited = visitedPlaces.has(place.id);
  const distanceText = userLocation ? getDistanceFromUser(place, userLocation) : null;

  const getMapUrl = (address: string, postcode: string) => {
    const query = encodeURIComponent(`${address}, ${postcode}, Cambridge, UK`);
    return `https://www.google.com/maps/search/?api=1&query=${query}`;
  };

  return (
    <OverlayWrapper>
      <div className="fixed inset-4 md:relative md:inset-auto bg-white rounded-xl shadow-xl overflow-hidden md:w-full md:max-w-2xl">
        {/* Image Section */}
        <div className="relative aspect-video">
          <img
            src={place.imageUrl}
            alt={place.name}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/20" />
          <button
            onClick={onClose}
            className="absolute top-4 right-4 p-2 bg-black/50 backdrop-blur-sm rounded-full text-white hover:bg-black/70 transition-colors"
          >
            <X className="w-6 h-6" />
          </button>

          {/* Distance Badge */}
          {distanceText && (
            <div className="absolute bottom-4 left-4 px-3 py-1.5 bg-black/50 backdrop-blur-sm rounded-lg text-white text-sm">
              <Navigation className="w-4 h-4 inline-block mr-1.5" />
              {distanceText}
            </div>
          )}

          {/* Share Button */}
          <div className="absolute bottom-4 right-4">
            <button
              onClick={() => setShowShareMenu(!showShareMenu)}
              className="p-2 bg-black/50 backdrop-blur-sm rounded-full text-white hover:bg-black/70 transition-colors"
            >
              <Share2 className="w-5 h-5" />
            </button>
            {showShareMenu && (
              <ShareMenu
                placeId={place.id}
                placeName={place.name}
                onClose={() => setShowShareMenu(false)}
              />
            )}
          </div>
        </div>

        {/* Tabs */}
        <div className="border-b border-gray-200">
          <div className="flex">
            <button
              onClick={() => setActiveTab('info')}
              className={`p-4 ${activeTab === 'info' ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-500'}`}
              title="Information"
            >
              <Info className="w-6 h-6" />
            </button>
            <button
              onClick={() => setActiveTab('location')}
              className={`p-4 ${activeTab === 'location' ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-500'}`}
              title="Location"
            >
              <MapPin className="w-6 h-6" />
            </button>
            <button
              onClick={() => setActiveTab('reviews')}
              className={`p-4 ${activeTab === 'reviews' ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-500'}`}
              title="Reviews"
            >
              <Star className="w-6 h-6" />
            </button>
            {place.specialOffer && isSignedIn && (
              <button
                onClick={() => setActiveTab('offers')}
                className={`p-4 ${activeTab === 'offers' ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-500'}`}
                title="Special Offers"
              >
                <Gift className="w-6 h-6" />
              </button>
            )}
            {isSignedIn && (
              <button
                onClick={() => setActiveTab('visited')}
                className={`p-4 ${activeTab === 'visited' ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-500'}`}
                title="Mark as Visited"
              >
                <CheckCircle className="w-6 h-6" />
              </button>
            )}
          </div>
        </div>

        {/* Content */}
        <div className="p-6 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 400px)' }}>
          {activeTab === 'info' && (
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-gray-900">{place.name}</h2>
              <p className="text-gray-600 whitespace-pre-line">{place.description}</p>
              
              <div className="flex flex-wrap gap-2">
                {(place.categories || [place.category]).map(cat => {
                  const Icon = categoryIcons[cat];
                  const colors = categoryColors[cat];
                  return (
                    <div 
                      key={cat}
                      className={`flex items-center gap-2 px-3 py-1 rounded-full text-sm ${colors.bg} ${colors.text}`}
                    >
                      <Icon className="w-4 h-4" />
                      {t(cat)}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {activeTab === 'location' && (
            <div className="space-y-4">
              {place.contact && (
                <>
                  <div className="flex items-start gap-3">
                    <MapPin className="w-5 h-5 text-gray-400 mt-1" />
                    <div>
                      <a 
                        href={getMapUrl(place.contact.address, place.contact.postcode)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-900 hover:text-indigo-600"
                      >
                        <p>{place.contact.address}</p>
                        <p>{place.contact.postcode}</p>
                      </a>
                      <a 
                        href={getMapUrl(place.contact.address, place.contact.postcode)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 mt-2 text-sm text-indigo-600 hover:text-indigo-700"
                      >
                        <Map className="w-4 h-4" />
                        <span>Get directions in Google Maps</span>
                      </a>
                    </div>
                  </div>
                  
                  {place.contact.phone && (
                    <div className="flex items-center gap-3">
                      <Phone className="w-5 h-5 text-gray-400" />
                      <a href={`tel:${place.contact.phone}`} className="text-gray-900 hover:text-indigo-600">
                        {place.contact.phone}
                      </a>
                    </div>
                  )}
                  
                  {place.contact.email && (
                    <div className="flex items-center gap-3">
                      <Mail className="w-5 h-5 text-gray-400" />
                      <a href={`mailto:${place.contact.email}`} className="text-gray-900 hover:text-indigo-600">
                        {place.contact.email}
                      </a>
                    </div>
                  )}
                </>
              )}

              {place.openingHours && (
                <div className="mt-6 space-y-2">
                  <h3 className="font-semibold text-gray-900 flex items-center gap-2">
                    <Clock className="w-5 h-5" />
                    Opening Hours
                  </h3>
                  {Object.entries(place.openingHours)
                    .filter(([key]) => key !== 'notes')
                    .map(([day, hours]) => (
                      <div key={day} className="flex justify-between">
                        <span className="text-gray-600 capitalize">{day}</span>
                        <span className="text-gray-900">{hours}</span>
                      </div>
                    ))
                  }
                  {place.openingHours.notes && (
                    <p className="text-sm text-gray-500 mt-2">{place.openingHours.notes}</p>
                  )}
                </div>
              )}
            </div>
          )}

          {activeTab === 'reviews' && (
            <div className="space-y-6">
              {isSignedIn && <ReviewForm placeId={place.id} placeName={place.name} onClose={onClose} />}
              
              {!isSignedIn && (
                <div className="text-center py-6">
                  <p className="text-gray-500">Please sign in to leave a review</p>
                </div>
              )}
            </div>
          )}

          {activeTab === 'offers' && place.specialOffer && (
            <div className="bg-gradient-to-r from-indigo-50 to-purple-50 p-4 rounded-lg">
              <div className="flex items-start gap-3">
                <Gift className="w-6 h-6 text-indigo-600 flex-shrink-0" />
                <div>
                  <h3 className="font-semibold text-gray-900">Special Offer</h3>
                  <p className="text-gray-700">{place.specialOffer.description}</p>
                  {place.specialOffer.validUntil && (
                    <p className="text-sm text-gray-500 mt-2">
                      Valid until {new Date(place.specialOffer.validUntil).toLocaleDateString()}
                    </p>
                  )}
                  {place.specialOffer.code && (
                    <div className="mt-3">
                      <span className="text-sm text-gray-600">Use code:</span>
                      <code className="ml-2 px-2 py-1 bg-white rounded border border-gray-200 text-indigo-600">
                        {place.specialOffer.code}
                      </code>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {activeTab === 'visited' && (
            <div className="space-y-6">
              <div className="bg-gradient-to-r from-indigo-50 to-purple-50 p-6 rounded-lg">
                <div className="flex items-start gap-4">
                  <div className={`w-12 h-12 rounded-full flex items-center justify-center ${
                    isVisited ? 'bg-green-100' : 'bg-gray-100'
                  }`}>
                    <CheckCircle className={`w-6 h-6 ${
                      isVisited ? 'text-green-600' : 'text-gray-400'
                    }`} />
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900 text-lg">
                      {isVisited ? "You've visited this place!" : "Have you been here?"}
                    </h3>
                    <p className="text-gray-600 text-sm">
                      {isVisited 
                        ? "Great! Your visit has been recorded. Keep exploring Cambridge!"
                        : "Mark this place as visited to track your Cambridge adventures."}
                    </p>
                    <button
                      onClick={() => toggleVisited(place.id)}
                      className={`mt-4 px-4 py-2 rounded-lg transition-colors ${
                        isVisited
                          ? 'bg-red-100 text-red-700 hover:bg-red-200'
                          : 'bg-green-100 text-green-700 hover:bg-green-200'
                      }`}
                    >
                      {isVisited ? "Remove from visited" : "Mark as visited"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Booking Button */}
        {place.bookingUrl && activeTab !== 'reviews' && (
          <div className="p-4 border-t">
            <a
              href={place.bookingUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full px-4 py-3 bg-indigo-600 text-white text-center rounded-lg hover:bg-indigo-700 transition-colors"
            >
              Book Now {place.price && `• ${place.price}`}
            </a>
          </div>
        )}
      </div>
    </OverlayWrapper>
  );
}