import { 
  Utensils, Beer, Landmark, TreePine, ShoppingBag, 
  Calendar, Map, Gamepad2, Hotel, Music, Coffee, 
  Mic2, Hammer, Building2, GraduationCap, Waves,
  Dumbbell, Heart, Sparkles, Trophy, School, Palette
} from 'lucide-react';

export const categoryIcons = {
  eating: Utensils,
  pubs: Beer,
  history: Landmark,
  outdoors: TreePine,
  shopping: ShoppingBag,
  events: Calendar,
  tours: Map,
  games: Gamepad2,
  stay: Hotel,
  entertainment: Music,
  coffee: Coffee,
  talks: Mic2,
  workshops: Hammer,
  museums: Building2,
  classes: GraduationCap,
  cam: Waves,
  sport: Trophy,
  fitness: Dumbbell,
  wellbeing: Heart,
  othersyde: Sparkles,
  colleges: School,
  art: Palette
} as const;