import React from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { X, Heart, MapPin } from 'lucide-react';
import { useAppState } from '../lib/state';
import { PLACES } from '../data/places';
import { categoryColors } from '../utils/categoryColors';
import { categoryIcons } from '../utils/categoryIcons';

interface FavoritesOverlayProps {
  onClose: () => void;
  onPlaceClick: (id: string) => void;
}

export function FavoritesOverlay({ onClose, onPlaceClick }: FavoritesOverlayProps) {
  const { favourites } = useAppState();
  const favouritePlaces = PLACES.filter(place => favourites.includes(place.id));

  return (
    <OverlayWrapper>
      <div className="fixed inset-4 bg-white rounded-xl shadow-xl overflow-hidden">
        {/* Header */}
        <div className="bg-gradient-to-r from-indigo-600 to-purple-600 p-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <Heart className="w-8 h-8 text-red-400 fill-current" />
              <h2 className="text-xl font-bold text-white">My Favourites</h2>
            </div>
            <button
              onClick={onClose}
              className="p-2 text-white hover:bg-white/10 rounded-lg"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
          <p className="text-white/80 mt-2">Places you love in Cambridge</p>
        </div>

        {/* Places List */}
        <div className="overflow-y-auto" style={{ height: 'calc(100% - 116px)' }}>
          {favouritePlaces.length > 0 ? (
            favouritePlaces.map(place => {
              const Icon = categoryIcons[place.category];
              const colors = categoryColors[place.category];

              return (
                <div
                  key={place.id}
                  className="p-4 border-b hover:bg-gray-50 cursor-pointer"
                  onClick={() => onPlaceClick(place.id)}
                >
                  <div className="flex gap-4">
                    {/* Place Image */}
                    <div className="w-24 h-24 rounded-lg overflow-hidden flex-shrink-0">
                      <img
                        src={place.imageUrl}
                        alt={place.name}
                        className="w-full h-full object-cover"
                      />
                    </div>

                    {/* Place Details */}
                    <div className="flex-1">
                      <div className="flex items-start justify-between">
                        <div>
                          <h3 className="font-semibold text-gray-900">{place.name}</h3>
                          <div className={`inline-flex items-center gap-1 px-2 py-1 rounded-full text-sm ${colors.bg} ${colors.text} mt-1`}>
                            <Icon className="w-4 h-4" />
                            <span>{place.category}</span>
                          </div>
                        </div>
                        <Heart className="w-5 h-5 text-red-500 fill-current" />
                      </div>

                      <p className="text-gray-600 mt-2 line-clamp-2">{place.description}</p>

                      {place.contact && (
                        <div className="flex items-center gap-2 mt-2 text-sm text-gray-500">
                          <MapPin className="w-4 h-4" />
                          <span>{place.contact.address}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="p-8 text-center">
              <div className="max-w-sm mx-auto space-y-4">
                <div className="w-24 h-24 mx-auto bg-gray-100 rounded-full flex items-center justify-center">
                  <Heart className="w-12 h-12 text-gray-400" />
                </div>
                <div className="space-y-2">
                  <p className="text-xl font-semibold text-gray-900">No favourites yet? 💝</p>
                  <p className="text-gray-500">
                    Start marking places you love with the heart icon. 
                    Your favourites will appear here for easy access!
                  </p>
                </div>
                <button
                  onClick={onClose}
                  className="px-6 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-lg
                    hover:from-indigo-500 hover:to-purple-500 transition-all duration-200 font-medium"
                >
                  Explore Places
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </OverlayWrapper>
  );
}