import React, { useState, useEffect } from 'react';
import { Plus, Edit2, Trash2, Save, X } from 'lucide-react';
import type { ScoringCriteria } from '../../types';
import { getScoringCriteria, createScoringCriteria } from '../../lib/scoring';

export function ScoringCriteriaManager() {
  const [criteria, setCriteria] = useState<ScoringCriteria[]>([]);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    weight: 1,
    maxScore: 10
  });

  useEffect(() => {
    loadCriteria();
  }, []);

  async function loadCriteria() {
    try {
      const data = await getScoringCriteria();
      setCriteria(data);
    } catch (err) {
      setError('Failed to load scoring criteria');
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await createScoringCriteria({
        ...formData,
        isActive: true
      });
      setIsAddingNew(false);
      setFormData({ name: '', description: '', weight: 1, maxScore: 10 });
      loadCriteria();
    } catch (err) {
      setError('Failed to create scoring criteria');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-gray-900">Scoring Criteria</h2>
        <button
          onClick={() => setIsAddingNew(true)}
          className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 
            transition-colors flex items-center gap-2"
        >
          <Plus className="w-4 h-4" />
          Add Criteria
        </button>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-600">
          {error}
        </div>
      )}

      {isAddingNew && (
        <form onSubmit={handleSubmit} className="mb-6 bg-gray-50 p-4 rounded-lg">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name
              </label>
              <input
                type="text"
                required
                value={formData.name}
                onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                value={formData.description}
                onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                rows={3}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Weight
                </label>
                <input
                  type="number"
                  min="1"
                  max="10"
                  required
                  value={formData.weight}
                  onChange={e => setFormData(prev => ({ ...prev, weight: parseInt(e.target.value) }))}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Max Score
                </label>
                <input
                  type="number"
                  min="1"
                  max="100"
                  required
                  value={formData.maxScore}
                  onChange={e => setFormData(prev => ({ ...prev, maxScore: parseInt(e.target.value) }))}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                />
              </div>
            </div>

            <div className="flex justify-end gap-2">
              <button
                type="button"
                onClick={() => setIsAddingNew(false)}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {criteria.map(criterion => (
          <div
            key={criterion.id}
            className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
          >
            <div>
              <h3 className="font-medium text-gray-900">{criterion.name}</h3>
              <p className="text-sm text-gray-600">{criterion.description}</p>
              <div className="flex gap-4 mt-1 text-sm text-gray-500">
                <span>Weight: {criterion.weight}</span>
                <span>Max Score: {criterion.maxScore}</span>
              </div>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setEditingId(criterion.id)}
                className="p-2 text-gray-600 hover:bg-gray-200 rounded-lg"
              >
                <Edit2 className="w-4 h-4" />
              </button>
              <button
                onClick={() => {/* Handle delete */}}
                className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}