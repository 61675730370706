import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus, Heart, BookmarkPlus, ThumbsUp, ThumbsDown, Gift, Star } from 'lucide-react';
import type { Place } from '../types';
import { categoryColors } from '../utils/categoryColors';
import { categoryIcons } from '../utils/categoryIcons';
import { useAppState } from '../lib/state';
import { getDistanceFromUser } from '../utils/distance';
import { RatingsOverlay } from './RatingsOverlay';

interface PlaceCardProps {
  place: Place;
  onShowDetails: (id: string, activeTab?: string) => void;
  userLocation?: { lat: number; lng: number } | null;
  onSignUp?: () => void;
  onSignIn?: () => void;
  rank?: number;
}

export function PlaceCard({ 
  place,
  onShowDetails,
  userLocation,
  onSignUp,
  onSignIn,
  rank
}: PlaceCardProps) {
  const { t } = useTranslation();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [showRatings, setShowRatings] = useState(false);

  const {
    isSignedIn,
    favourites,
    bookmarks,
    toggleFavourite,
    toggleBookmark,
    addVote,
    hasVoted,
    getVoteTotal,
    getAllReviews
  } = useAppState();

  const reviews = getAllReviews(place.id);
  const averageRating = reviews.length > 0
    ? reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length
    : 0;

  const distanceText = userLocation ? getDistanceFromUser(place, userLocation) : null;

  const handleVote = (e: React.MouseEvent, direction: 'up' | 'down') => {
    e.stopPropagation();
    if (!isSignedIn) {
      onSignUp?.();
      return;
    }
    if (hasVoted(place.id)) return;
    addVote(place.id, direction);
  };

  const handleFavourite = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isSignedIn) {
      onSignUp?.();
      return;
    }
    toggleFavourite(place.id);
  };

  const handleBookmark = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isSignedIn) {
      onSignUp?.();
      return;
    }
    toggleBookmark(place.id);
  };

  const handleReviewsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isSignedIn) {
      onSignUp?.();
      return;
    }
    onShowDetails(place.id, 'reviews');
  };

  const handleSpecialOfferClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isSignedIn) {
      onSignUp?.();
      return;
    }
    onShowDetails(place.id, 'offers');
  };

  return (
    <div className="relative bg-white/90 shadow-md overflow-hidden rounded-lg">
      <div className="relative aspect-[16/9] overflow-hidden bg-gray-100">
        {/* Rank Badge */}
        {rank && (
          <div className={`absolute top-2 left-2 w-8 h-8 flex items-center justify-center rounded-full font-bold z-20 ${
            rank === 1 ? 'bg-yellow-100 text-yellow-800 border-2 border-yellow-400' :
            rank === 2 ? 'bg-gray-100 text-gray-800 border-2 border-gray-400' :
            rank === 3 ? 'bg-orange-100 text-orange-800 border-2 border-orange-400' :
            'bg-white text-gray-600 border border-gray-200'
          }`}>
            {rank}
          </div>
        )}

        {/* Category Icons */}
        {(place.categories || [place.category]).map((cat, index) => {
          const Icon = categoryIcons[cat];
          const colors = categoryColors[cat];
          return (
            <div 
              key={cat}
              className={`absolute top-2 p-2 rounded-full ${colors.bg} backdrop-blur-sm shadow-md z-10`}
              style={{ left: `${index * 48 + (rank ? 48 : 8)}px` }}
            >
              <Icon className={`w-5 h-5 ${colors.text}`} />
            </div>
          );
        })}

        {/* Loading State */}
        {!imageLoaded && !imageError && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
            <div className="w-8 h-8 border-4 border-indigo-600 border-t-transparent rounded-full animate-spin" />
          </div>
        )}

        {/* Image */}
        <img 
          src={place.imageUrl} 
          alt={place.name}
          loading="lazy"
          className={`w-full h-full object-cover transition-opacity duration-300
            ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
          onLoad={() => setImageLoaded(true)}
          onError={() => {
            setImageError(true);
            setImageLoaded(true);
          }}
        />

        {/* Rating Badge */}
        {reviews.length > 0 && (
          <div className="absolute bottom-2 left-2 px-2 py-1 bg-black/50 backdrop-blur-sm rounded-lg text-white flex items-center gap-1 z-10">
            <Star className="w-4 h-4 text-yellow-400 fill-current" />
            <span className="text-sm font-medium">
              {averageRating.toFixed(1)}
            </span>
            <span className="text-xs text-white/70">({reviews.length})</span>
          </div>
        )}

        {/* Distance Badge */}
        {distanceText && (
          <div className="absolute bottom-2 left-2 px-2 py-1 bg-black/50 backdrop-blur-sm rounded-lg text-white text-sm z-10">
            {distanceText}
          </div>
        )}

        {/* Special Offer Icon */}
        {place.specialOffer && (
          <button 
            onClick={handleSpecialOfferClick}
            className={`absolute top-2 right-2 z-10 p-2 rounded-full shadow-lg
              ${isSignedIn 
                ? 'bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer' 
                : 'bg-gray-400 text-white cursor-not-allowed'} 
              transition-all duration-300`}
            title={t('cards.specialOffer')}
          >
            <Gift className="w-5 h-5" />
          </button>
        )}

        {/* Action Icons */}
        <div className="absolute bottom-2 right-2 flex items-center gap-2">
          <button 
            onClick={handleFavourite}
            className={`p-2 rounded-full bg-black/50 hover:bg-black/70 backdrop-blur-sm
              transition-colors duration-200`}
          >
            <Heart 
              className={`w-5 h-5 ${favourites.includes(place.id) ? 'text-red-500 fill-current' : 'text-white'}`}
            />
          </button>
          <button 
            onClick={handleBookmark}
            className={`p-2 rounded-full bg-black/50 hover:bg-black/70 backdrop-blur-sm
              transition-colors duration-200`}
          >
            <BookmarkPlus 
              className={`w-5 h-5 ${bookmarks.includes(place.id) ? 'text-purple-500 fill-current' : 'text-white'}`}
            />
          </button>
        </div>
      </div>

      <div className="p-4">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">{place.name}</h3>
        <p className="text-sm text-gray-600 mb-4">{place.description.split('\n\n')[0]}</p>

        <div className="flex flex-wrap gap-2">
          {(place.categories || [place.category]).map(cat => {
            const Icon = categoryIcons[cat];
            const colors = categoryColors[cat];
            return (
              <div 
                key={cat}
                className={`flex items-center gap-2 px-3 py-1 rounded-full text-xs font-medium ${colors.bg} ${colors.text}`}
              >
                <Icon className="w-4 h-4" />
                {t(cat)}
              </div>
            );
          })}
        </div>

        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center gap-3">
            <button
              onClick={(e) => handleVote(e, 'up')}
              className={`p-2 rounded-lg flex items-center gap-1.5 transition-colors
                ${!isSignedIn || hasVoted(place.id)
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-green-50 text-green-600 hover:bg-green-100 active:bg-green-200'}`}
              disabled={!isSignedIn || hasVoted(place.id)}
            >
              <ThumbsUp className="w-5 h-5" />
            </button>
            <span className="font-semibold text-gray-700 min-w-[2ch] text-center">
              {getVoteTotal(place.id)}
            </span>
            <button
              onClick={(e) => handleVote(e, 'down')}
              className={`p-2 rounded-lg flex items-center gap-1.5 transition-colors
                ${!isSignedIn || hasVoted(place.id)
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-red-50 text-red-600 hover:bg-red-100 active:bg-red-200'}`}
              disabled={!isSignedIn || hasVoted(place.id)}
            >
              <ThumbsDown className="w-5 h-5" />
            </button>
          </div>

          <div className="flex items-center gap-2">
            <button
              onClick={() => setShowRatings(true)}
              className="p-3 md:p-2 rounded-full bg-yellow-100 hover:bg-yellow-200 active:bg-yellow-300 transition-colors group"
            >
              <Star className="w-7 h-7 md:w-6 md:h-6 text-yellow-600 group-hover:scale-110 transition-transform" />
            </button>
            <button
              onClick={() => onShowDetails(place.id)}
              className="p-3 md:p-2 rounded-full bg-indigo-100 hover:bg-indigo-200 active:bg-indigo-300 transition-colors group touch-manipulation"
              style={{ WebkitTapHighlightColor: 'transparent' }}
              title={t('cards.showMore')}
            >
              <Plus className="w-7 h-7 md:w-6 md:h-6 text-indigo-600 group-hover:scale-110 transition-transform" />
            </button>
          </div>
        </div>
      </div>

      {/* Ratings Overlay */}
      {showRatings && (
        <RatingsOverlay
          place={place}
          onClose={() => setShowRatings(false)}
        />
      )}
    </div>
  );
}