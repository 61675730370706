import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import './index.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Initialize i18n
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'],
    defaultNS: 'common',
    fallbackNS: 'common',
    resources: {
      en: {
        common: {
          hero: {
            title: "Discover Cambridge",
            subtitle: "Explore the finest places and activities in our historic city",
            joinToday: "Join Today",
            specialOffers: "Special Offers"
          }
        }
      },
      fr: {
        common: {
          hero: {
            title: "Découvrez Cambridge",
            subtitle: "Explorez les meilleurs endroits et activités de notre ville historique",
            joinToday: "Rejoignez-nous",
            specialOffers: "Offres Spéciales"
          }
        }
      }
    },
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage']
    }
  });

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Root element not found');

createRoot(rootElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);