import React from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { X, ThumbsUp, ThumbsDown, Brain, MapPin, Trophy } from 'lucide-react';
import type { QuizQuestion, QuizStats } from '../types';
import { RelatedPlacesOverlay } from './RelatedPlacesOverlay';

interface QuizOverlayProps {
  question: QuizQuestion;
  onClose: () => void;
  onAnswer: (correct: boolean) => void;
  stats: QuizStats;
  isSignedIn?: boolean;
}

export function QuizOverlay({ question, onClose, onAnswer, stats, isSignedIn }: QuizOverlayProps) {
  const [showAnswer, setShowAnswer] = React.useState(false);
  const [showRelatedPlaces, setShowRelatedPlaces] = React.useState(false);

  const handleAnswerSubmit = (correct: boolean) => {
    onAnswer(correct);
  };

  const handleFindOutMore = () => {
    setShowRelatedPlaces(true);
  };

  const percentageCorrect = stats.questionsAnswered > 0
    ? Math.round((stats.correctAnswers / stats.questionsAnswered) * 100)
    : 0;

  return (
    <OverlayWrapper>
      <div className="max-h-[calc(100vh-2rem)] flex flex-col">
        {/* Header */}
        <div className="flex-none p-6 border-b border-white/10">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <Brain className="w-8 h-8 text-indigo-400" />
              <h2 className="text-2xl font-bold text-white">Cambridge Quiz</h2>
            </div>
            <button 
              onClick={onClose}
              className="p-2 text-white hover:bg-white/10 rounded-lg transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
          {isSignedIn && (
            <div className="mt-4 flex items-center gap-4">
              <div className="flex items-center gap-2">
                <Trophy className="w-5 h-5 text-yellow-400" />
                <span className="text-white/80">Questions: {stats.questionsAnswered}</span>
              </div>
              <div className="flex items-center gap-2">
                <ThumbsUp className="w-5 h-5 text-green-400" />
                <span className="text-white/80">Success: {percentageCorrect}%</span>
              </div>
            </div>
          )}
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto">
          <div className="p-6">
            <div className="max-w-2xl mx-auto space-y-6">
              {/* Image */}
              <div className="aspect-video rounded-xl overflow-hidden bg-black/20">
                <img 
                  src={question.imageUrl} 
                  alt="Historical figure"
                  className="w-full h-full object-cover"
                />
              </div>

              {showAnswer ? (
                <>
                  <div className="bg-white/10 rounded-xl p-6 space-y-4">
                    <div>
                      <h3 className="text-2xl font-bold text-white mb-2">
                        {question.answer}
                      </h3>
                      <p className="text-white/70">{question.years}</p>
                      <p className="text-white/90 mt-4">{question.bio}</p>
                    </div>

                    <div className="space-y-3">
                      {question.funFacts.map((fact, index) => (
                        <p key={index} className="text-white/80 flex items-start gap-2">
                          <span className="text-indigo-400 mt-1">•</span>
                          {fact}
                        </p>
                      ))}
                    </div>

                    <div className="flex gap-3 pt-4">
                      <button
                        onClick={() => handleAnswerSubmit(true)}
                        className="flex-1 flex flex-col items-center justify-center gap-2 px-4 py-4 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                      >
                        <ThumbsUp className="w-8 h-8" />
                        <span>I Knew This!</span>
                      </button>
                      <button
                        onClick={() => handleAnswerSubmit(false)}
                        className="flex-1 flex flex-col items-center justify-center gap-2 px-4 py-4 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
                      >
                        <ThumbsDown className="w-8 h-8" />
                        <span>Didn't Know</span>
                      </button>
                    </div>
                  </div>

                  <button
                    onClick={handleFindOutMore}
                    className="w-full flex items-center justify-center gap-2 px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                  >
                    <MapPin className="w-5 h-5" />
                    Discover Related Places
                  </button>
                </>
              ) : (
                <div className="bg-white/10 rounded-xl p-6 space-y-6">
                  <p className="text-xl text-white">{question.question}</p>
                  <button
                    onClick={() => setShowAnswer(true)}
                    className="w-full px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                  >
                    Show Answer
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Related Places Overlay */}
      {showRelatedPlaces && (
        <RelatedPlacesOverlay
          question={question}
          onClose={() => setShowRelatedPlaces(false)}
        />
      )}
    </OverlayWrapper>
  );
}