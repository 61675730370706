import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { UserProfile, QuizStats } from '../types';
import { signOut as authSignOut } from './auth';

interface AppState {
  isSignedIn: boolean;
  userProfile: UserProfile | null;
  userPoints: number;
  favourites: string[];
  bookmarks: string[];
  visitedPlaces: Set<string>;
  reviews: Map<string, { rating: number; comment: string; date: string }>;
  votes: Map<string, 'up' | 'down'>;
  hasClaimedRetentionBonus: boolean;
  quizStats: QuizStats;
  shares: Map<string, { 
    placeId: string;
    shareId: string;
    timestamp: string;
    platform: 'twitter' | 'facebook' | 'whatsapp' | 'email' | 'copy';
    converted?: boolean;
  }[]>;
  lastBonusPoints: { points: number; action: 'favourite' | 'bookmark' | 'review' | 'visited' | 'quiz' | 'share' | 'referral' } | null;
  preferences: {
    isResident: boolean;
    isEnglishSpeaker: boolean;
    isDayVisitor: boolean;
    isShortStay: boolean;
    isStudent: boolean;
    hasChildren: boolean;
    interestedInHistory: boolean;
    interestedInArts: boolean;
    interestedInNature: boolean;
    usePublicTransport: boolean;
  };
  
  // Actions
  signIn: (data: { name: string; email: string; password: string; role?: string }) => void;
  signOut: () => Promise<void>;
  deleteAccount: () => Promise<void>;
  switchRole: (role: 'user' | 'admin' | 'venue_owner') => void;
  toggleFavourite: (placeId: string) => void;
  toggleBookmark: (placeId: string) => void;
  toggleVisited: (placeId: string) => void;
  addReview: (placeId: string, rating: number, comment: string) => void;
  updateReview: (placeId: string, rating: number, comment: string) => void;
  deleteReview: (placeId: string) => void;
  addVote: (placeId: string, direction: 'up' | 'down') => void;
  hasVoted: (placeId: string) => boolean;
  getVoteTotal: (placeId: string) => number;
  getAllReviews: (placeId?: string) => any[];
  awardRetentionBonus: () => boolean;
  updateQuizStats: (stats: QuizStats) => void;
  addPoints: (amount: number) => void;
  toggleShare: (placeId: string, platform: 'twitter' | 'facebook' | 'whatsapp' | 'email' | 'copy') => void;
  awardReferralPoints: (referrerId: string, shareId: string) => void;
  clearLastBonusPoints: () => void;
  updatePreferences: (newPreferences: Partial<AppState['preferences']>) => void;
}

const initialPreferences = {
  isResident: false,
  isEnglishSpeaker: true,
  isDayVisitor: false,
  isShortStay: false,
  isStudent: false,
  hasChildren: false,
  interestedInHistory: false,
  interestedInArts: false,
  interestedInNature: false,
  usePublicTransport: false,
};

const initialState = {
  isSignedIn: false,
  userProfile: null,
  userPoints: 0,
  favourites: [],
  bookmarks: [],
  visitedPlaces: new Set<string>(),
  reviews: new Map(),
  votes: new Map(),
  hasClaimedRetentionBonus: false,
  quizStats: {
    questionsAnswered: 0,
    correctAnswers: 0,
    correctlyAnsweredIds: [],
    incorrectlyAnsweredIds: []
  },
  shares: new Map(),
  lastBonusPoints: null,
  preferences: initialPreferences
};

export const useAppState = create<AppState>()(
  persist(
    (set, get) => ({
      ...initialState,

      signIn: (data) => set({
        isSignedIn: true,
        userProfile: {
          id: 'test-user',
          name: data.name,
          email: data.email,
          role: data.role || 'user',
          placeInteractions: {},
          quiz_stats: {
            questionsAnswered: 0,
            correctAnswers: 0,
            correctlyAnsweredIds: [],
            incorrectlyAnsweredIds: []
          }
        },
        userPoints: 0,
        favourites: [],
        bookmarks: [],
        visitedPlaces: new Set<string>(),
        reviews: new Map(),
        votes: new Map(),
        shares: new Map(),
        lastBonusPoints: null,
        preferences: initialPreferences
      }),

      signOut: async () => {
        const { error } = await authSignOut();
        if (!error) {
          localStorage.removeItem('mycam-storage');
          set(initialState);
        }
      },

      deleteAccount: async () => {
        try {
          localStorage.removeItem('mycam-storage');
          set(initialState);
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      },

      switchRole: (role) => set(state => ({
        userProfile: state.userProfile ? {
          ...state.userProfile,
          role
        } : null
      })),

      toggleFavourite: (placeId) => set(state => {
        if (!state.isSignedIn) return state;

        const isFirstFavourite = state.favourites.length === 0;
        const newFavourites = state.favourites.includes(placeId)
          ? state.favourites.filter(id => id !== placeId)
          : [...state.favourites, placeId];
        
        const pointsToAdd = isFirstFavourite && !state.favourites.includes(placeId) ? 50 : 0;

        return {
          favourites: newFavourites,
          userPoints: state.userPoints + pointsToAdd,
          lastBonusPoints: pointsToAdd ? { points: pointsToAdd, action: 'favourite' } : null
        };
      }),

      toggleBookmark: (placeId) => set(state => {
        if (!state.isSignedIn) return state;

        const isFirstBookmark = state.bookmarks.length === 0;
        const newBookmarks = state.bookmarks.includes(placeId)
          ? state.bookmarks.filter(id => id !== placeId)
          : [...state.bookmarks, placeId];
        
        const pointsToAdd = isFirstBookmark && !state.bookmarks.includes(placeId) ? 50 : 0;

        return {
          bookmarks: newBookmarks,
          userPoints: state.userPoints + pointsToAdd,
          lastBonusPoints: pointsToAdd ? { points: pointsToAdd, action: 'bookmark' } : null
        };
      }),

      toggleVisited: (placeId) => set(state => {
        if (!state.isSignedIn) return state;

        const newVisited = new Set(state.visitedPlaces);
        const wasVisited = newVisited.has(placeId);
        
        if (wasVisited) {
          newVisited.delete(placeId);
        } else {
          newVisited.add(placeId);
        }

        const pointsToAdd = !wasVisited && state.visitedPlaces.size === 0 ? 25 : 0;

        return { 
          visitedPlaces: newVisited,
          userPoints: state.userPoints + pointsToAdd,
          lastBonusPoints: pointsToAdd ? { points: pointsToAdd, action: 'visited' } : null
        };
      }),

      addReview: (placeId, rating, comment) => set(state => {
        if (!state.isSignedIn) return state;

        const isFirstReview = state.reviews.size === 0;
        const newReviews = new Map(state.reviews);
        newReviews.set(placeId, { rating, comment, date: new Date().toISOString() });
        
        const pointsToAdd = isFirstReview ? 100 : 0;

        return { 
          reviews: newReviews,
          userPoints: state.userPoints + pointsToAdd,
          lastBonusPoints: pointsToAdd ? { points: pointsToAdd, action: 'review' } : null
        };
      }),

      updateReview: (placeId, rating, comment) => set(state => {
        if (!state.isSignedIn) return state;

        const newReviews = new Map(state.reviews);
        const existingReview = newReviews.get(placeId);
        if (existingReview) {
          newReviews.set(placeId, { ...existingReview, rating, comment });
        }
        return { reviews: newReviews };
      }),

      deleteReview: (placeId) => set(state => {
        if (!state.isSignedIn) return state;

        const newReviews = new Map(state.reviews);
        newReviews.delete(placeId);
        return { reviews: newReviews };
      }),

      addVote: (placeId, direction) => set(state => {
        if (!state.isSignedIn) return state;

        const newVotes = new Map(state.votes);
        newVotes.set(placeId, direction);
        return { votes: newVotes };
      }),

      hasVoted: (placeId) => get().votes.has(placeId),

      getVoteTotal: (placeId) => {
        const votes = Array.from(get().votes.entries())
          .filter(([id]) => id === placeId)
          .map(([_, direction]) => direction === 'up' ? 1 : -1);
        return votes.reduce((sum, vote) => sum + vote, 0);
      },

      getAllReviews: (placeId) => {
        const reviews = get().reviews;
        if (placeId) {
          const review = reviews.get(placeId);
          return review ? [{ placeId, ...review }] : [];
        }
        return Array.from(reviews.entries()).map(([placeId, review]) => ({
          placeId,
          ...review
        }));
      },

      awardRetentionBonus: () => {
        if (!get().isSignedIn || get().hasClaimedRetentionBonus) {
          return false;
        }
        set(state => ({
          userPoints: state.userPoints + 100,
          hasClaimedRetentionBonus: true
        }));
        return true;
      },

      updateQuizStats: (stats) => set(state => {
        if (!state.isSignedIn) return state;

        const newCorrectAnswers = stats.correctAnswers - state.quizStats.correctAnswers;
        const pointsToAdd = Math.max(0, newCorrectAnswers * 25);
        
        return {
          quizStats: stats,
          userPoints: state.userPoints + pointsToAdd,
          lastBonusPoints: pointsToAdd ? { points: pointsToAdd, action: 'quiz' } : null
        };
      }),

      addPoints: (amount) => set(state => {
        if (!state.isSignedIn) return state;

        return {
          userPoints: state.userPoints + amount
        };
      }),

      toggleShare: (placeId, platform) => set(state => {
        const isFirstShare = state.shares.size === 0;
        const newShares = new Map(state.shares);
        const placeShares = newShares.get(placeId) || [];
        
        const shareId = crypto.randomUUID();
        placeShares.push({
          placeId,
          shareId,
          platform,
          timestamp: new Date().toISOString()
        });
        
        newShares.set(placeId, placeShares);

        const pointsToAdd = isFirstShare ? 50 : 0;

        return {
          shares: newShares,
          userPoints: state.userPoints + pointsToAdd,
          lastBonusPoints: pointsToAdd ? { points: pointsToAdd, action: 'share' } : null
        };
      }),

      awardReferralPoints: (referrerId, shareId) => {
        set(state => {
          const referrerShares = state.shares.get(referrerId) || [];
          const share = referrerShares.find(s => s.shareId === shareId);
          
          if (share && !share.converted) {
            return {
              userPoints: state.userPoints + 100,
              lastBonusPoints: { points: 100, action: 'referral' },
              shares: new Map(state.shares).set(referrerId, 
                referrerShares.map(s => 
                  s.shareId === shareId ? { ...s, converted: true } : s
                )
              )
            };
          }
          return state;
        });
      },

      clearLastBonusPoints: () => set({ lastBonusPoints: null }),

      updatePreferences: (newPreferences) => set(state => ({
        preferences: {
          ...state.preferences,
          ...newPreferences
        }
      }))
    }),
    {
      name: 'mycam-storage',
      storage: {
        getItem: (name) => {
          const str = localStorage.getItem(name);
          if (!str) return null;
          const { state } = JSON.parse(str);
          return {
            state: {
              ...state,
              visitedPlaces: new Set(state.visitedPlaces || []),
              reviews: new Map(Object.entries(state.reviews || {})),
              votes: new Map(Object.entries(state.votes || {})),
              shares: new Map(Object.entries(state.shares || {}))
            }
          };
        },
        setItem: (name, value) => {
          const { state } = value;
          const serialized = {
            state: {
              ...state,
              visitedPlaces: Array.from(state.visitedPlaces || []),
              reviews: Object.fromEntries(state.reviews || new Map()),
              votes: Object.fromEntries(state.votes || new Map()),
              shares: Object.fromEntries(state.shares || new Map())
            }
          };
          localStorage.setItem(name, JSON.stringify(serialized));
        },
        removeItem: (name) => localStorage.removeItem(name)
      }
    }
  )
);