import React, { useState } from 'react';
import { ScoringCriteriaManager } from './ScoringCriteriaManager';
import { PlaceScoring } from './PlaceScoring';
import { BiddingManager } from './BiddingManager';
import { PLACES } from '../../data/places';

export function AdminDashboard() {
  const [selectedPlace, setSelectedPlace] = useState<string | null>(null);

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Admin Dashboard</h1>

        <div className="grid gap-8 grid-cols-1 lg:grid-cols-2">
          <div className="space-y-8">
            <ScoringCriteriaManager />
            <BiddingManager />
          </div>

          <div>
            <div className="bg-white rounded-lg shadow-md p-6 mb-8">
              <h2 className="text-xl font-bold text-gray-900 mb-4">Score Places</h2>
              <select
                value={selectedPlace || ''}
                onChange={e => setSelectedPlace(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              >
                <option value="">Select a place to score...</option>
                {PLACES.map(place => (
                  <option key={place.id} value={place.id}>
                    {place.name}
                  </option>
                ))}
              </select>
            </div>

            {selectedPlace && (
              <PlaceScoring
                place={PLACES.find(p => p.id === selectedPlace)!}
                onScoreUpdate={() => {
                  // Handle score update
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}