import React from 'react';
import { X, Check } from 'lucide-react';
import { OverlayWrapper } from './OverlayWrapper';
import { useTranslation } from 'react-i18next';

interface LanguagesOverlayProps {
  onClose: () => void;
  onLanguageSelect: (lang: string) => void;
}

export function LanguagesOverlay({ onClose, onLanguageSelect }: LanguagesOverlayProps) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language?.split('-')[0] || 'en';

  const languages = [
    { code: 'en', name: 'English', flag: '🇬🇧' },
    { code: 'fr', name: 'Français', flag: '🇫🇷' }
  ];

  const handleLanguageSelect = async (langCode: string) => {
    try {
      await i18n.changeLanguage(langCode);
      localStorage.setItem('i18nextLng', langCode);
      onLanguageSelect(langCode);
      onClose();
    } catch (error) {
      console.error('Error changing language:', error);
    }
  };

  return (
    <OverlayWrapper>
      <div className="fixed inset-4 bg-gradient-to-br from-indigo-900 via-purple-900 to-[#1A1F2B] rounded-xl shadow-xl overflow-hidden">
        <div className="h-full flex flex-col">
          <div className="flex justify-between items-center p-4 border-b border-white/10">
            <h2 className="text-xl font-bold text-white">Select Language</h2>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-white/10 rounded-lg text-white"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="flex-1 overflow-y-auto p-4">
            <div className="grid grid-cols-1 gap-2">
              {languages.map(lang => (
                <button
                  key={lang.code}
                  onClick={() => handleLanguageSelect(lang.code)}
                  className={`w-full px-4 py-3 flex items-center justify-between rounded-lg 
                    ${currentLanguage === lang.code ? 'bg-white/10' : 'hover:bg-white/10'} 
                    text-white transition-colors`}
                >
                  <div className="flex items-center gap-3">
                    <span className="text-2xl">{lang.flag}</span>
                    <span>{lang.name}</span>
                  </div>
                  {currentLanguage === lang.code && (
                    <Check className="w-5 h-5 text-green-400" />
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </OverlayWrapper>
  );
}