import React from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { QuizQuestion } from '../types';
import { X, MapPin, ExternalLink, Building, Calendar } from 'lucide-react';
import { PLACES } from '../data/places';
import { categoryColors } from '../utils/categoryColors';
import { categoryIcons } from '../utils/categoryIcons';

interface RelatedPlacesOverlayProps {
  question: QuizQuestion;
  onClose: () => void;
}

export function RelatedPlacesOverlay({ question, onClose }: RelatedPlacesOverlayProps) {
  const relatedPlaces = question.relatedPlaces.map(related => ({
    ...related,
    place: PLACES.find(p => p.id === related.placeId)
  })).filter(item => item.place);

  return (
    <OverlayWrapper>
      <div className="bg-white w-full max-w-2xl mx-4 rounded-xl shadow-xl overflow-hidden">
        {/* Header */}
        <div className="bg-gray-900 p-6">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-white">
              Discover {question.name}'s Cambridge
            </h2>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-gray-800 rounded-full transition-colors"
            >
              <X className="w-6 h-6 text-gray-400" />
            </button>
          </div>
        </div>

        <div className="p-6">
          <div className="space-y-6">
            {relatedPlaces.map(({ place, description }) => {
              if (!place) return null;
              const Icon = categoryIcons[place.category];
              const colors = categoryColors[place.category];

              return (
                <div key={place.id} className="flex gap-4">
                  <div className="w-48 h-32 rounded-lg overflow-hidden flex-shrink-0">
                    <img 
                      src={place.imageUrl} 
                      alt={place.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex-1">
                    <div className="flex items-start justify-between gap-4">
                      <div>
                        <h3 className="text-lg font-semibold text-gray-900 mb-1">
                          {place.name}
                        </h3>
                        <div className={`inline-flex items-center gap-1 px-2 py-1 rounded-full text-sm ${colors.bg} ${colors.text}`}>
                          <Icon className="w-4 h-4" />
                          <span>{place.category}</span>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        {place.websiteUrl && (
                          <a
                            href={place.websiteUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                          >
                            <ExternalLink className="w-5 h-5 text-gray-600" />
                          </a>
                        )}
                        {place.bookingUrl && (
                          <a
                            href={place.bookingUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                          >
                            <Calendar className="w-5 h-5 text-gray-600" />
                          </a>
                        )}
                      </div>
                    </div>
                    <p className="text-gray-600 mt-2">{description}</p>
                    {place.contact && (
                      <div className="flex items-center gap-2 mt-2 text-sm text-gray-500">
                        <MapPin className="w-4 h-4" />
                        <span>{place.contact.address}</span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {/* Footer */}
          <div className="mt-8 pt-6 border-t">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2 text-gray-600">
                <Building className="w-5 h-5" />
                <span>Explore these locations to learn more about {question.name}</span>
              </div>
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </OverlayWrapper>
  );
}