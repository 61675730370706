import { QuizQuestion } from '../types';

export const QUIZ_QUESTIONS: QuizQuestion[] = [
  {
    id: 'newton',
    question: "I formulated the laws of motion and universal gravitation. Who am I?",
    answer: "Sir Isaac Newton",
    imageUrl: "https://images.unsplash.com/photo-1564473185935-58113cba1e80?w=400&h=400&fit=crop",
    name: "Sir Isaac Newton",
    years: "1643-1727",
    bio: "One of history's most influential scientists, I was a fellow at Trinity College and developed groundbreaking theories in physics and mathematics.",
    achievements: [
      "Developed the laws of motion and universal gravitation",
      "Invented calculus",
      "Made breakthrough discoveries in optics",
      "Served as Lucasian Professor of Mathematics",
      "President of the Royal Society"
    ],
    funFacts: [
      "I allegedly discovered gravity when an apple fell on my head (though this story is likely apocryphal)",
      "I was born prematurely and wasn't expected to survive",
      "I served as Warden of the Royal Mint and prosecuted counterfeiters",
      "The famous quote 'standing on the shoulders of giants' was written in a letter to Robert Hooke"
    ],
    category: 'science',
    relatedPlaces: [
      {
        placeId: '1',
        description: "Visit Trinity College, where Newton was a fellow and conducted many of his groundbreaking experiments. The famous apple tree story is commemorated in the college grounds."
      },
      {
        placeId: '3',
        description: "Explore Stourbridge Common, where Newton is said to have purchased the glass prism he used in his revolutionary experiments with light and color."
      }
    ]
  },
  {
    id: 'barrett',
    question: "I was Pink Floyd's original frontman and wrote their early psychedelic hits. Who am I?",
    answer: "Syd Barrett",
    imageUrl: "https://images.unsplash.com/photo-1516916759473-600c07bc12d4?w=400&h=400&fit=crop",
    name: "Syd Barrett",
    years: "1946-2006",
    bio: "I was a founding member of Pink Floyd and helped pioneer psychedelic rock before leaving the band in 1968.",
    achievements: [
      "Co-founded Pink Floyd",
      "Wrote most of Pink Floyd's first album 'The Piper at the Gates of Dawn'",
      "Released two solo albums",
      "Influenced countless musicians in psychedelic and alternative rock"
    ],
    funFacts: [
      "I was born and raised in Cambridge",
      "I attended Cambridge School of Art",
      "After leaving music, I returned to Cambridge and became a painter",
      "Pink Floyd's 'Wish You Were Here' was partly written about me"
    ],
    category: 'arts',
    relatedPlaces: [
      {
        placeId: '2',
        description: "Visit the Cambridge School of Art where Barrett studied before forming Pink Floyd. The building still houses many art students and occasionally displays Barrett's artwork."
      },
      {
        placeId: '4',
        description: "Take a guided tour of Barrett's Cambridge, including his childhood home on Hills Road and his later residence on St. Margaret's Square where he lived until his passing in 2006."
      }
    ]
  },
  {
    id: 'crick',
    question: "My colleague and I announced 'we have discovered the secret of life' at The Eagle pub after finding DNA's structure. Who am I?",
    answer: "Francis Crick",
    imageUrl: "https://images.unsplash.com/photo-1576086213369-97a306d36557?w=400&h=400&fit=crop",
    name: "Francis Crick",
    years: "1916-2004",
    bio: "Along with James Watson, I co-discovered the double helix structure of DNA, revolutionizing our understanding of genetics and heredity.",
    achievements: [
      "Co-discovered the structure of DNA",
      "Nobel Prize in Physiology or Medicine (1962)",
      "Fellow of the Royal Society",
      "Order of Merit recipient",
      "Made significant contributions to molecular biology and neuroscience"
    ],
    funFacts: [
      "I announced the DNA discovery during lunch at The Eagle pub",
      "I initially studied physics before switching to biology",
      "The double helix model was partly inspired by a spiral staircase",
      "I later focused on studying consciousness and the brain"
    ],
    category: 'science',
    relatedPlaces: [
      {
        placeId: 'pub-1',
        description: "Visit The Eagle pub where Crick and Watson famously announced their DNA discovery in 1953. The pub maintains a commemorative plaque and celebrates its connection to this groundbreaking scientific moment."
      }
    ]
  },
  {
    id: 'byron',
    question: "I kept a bear in my Trinity College rooms and became one of the leading figures of the Romantic movement. Who am I?",
    answer: "Lord Byron",
    imageUrl: "https://images.unsplash.com/photo-1524668951403-d44b28200ce0?w=400&h=400&fit=crop",
    name: "Lord Byron",
    years: "1788-1824",
    bio: "I was a leading figure in the Romantic movement and one of Britain's most famous poets, known for my flamboyant lifestyle and narrative poems.",
    achievements: [
      "Wrote influential works like 'Don Juan' and 'Childe Harold's Pilgrimage'",
      "Member of the House of Lords",
      "Inspired the Greek independence movement",
      "Created the 'Byronic hero' archetype",
      "Published first collection of poems while at Cambridge"
    ],
    funFacts: [
      "I kept a bear in my Trinity College rooms when dogs were banned",
      "I was known as 'mad, bad, and dangerous to know'",
      "I swam the Hellespont (Dardanelles) strait in Turkey",
      "My daughter Ada Lovelace became the world's first computer programmer"
    ],
    category: 'arts',
    relatedPlaces: [
      {
        placeId: 'college-1',
        description: "Visit Trinity College where Byron studied and famously kept his pet bear. His rooms are marked with a plaque, and the college library holds several of his manuscripts and personal items."
      }
    ]
  },
  {
    id: 'attenborough',
    question: "I studied Natural Sciences at Clare College before becoming the world's most famous natural history broadcaster. Who am I?",
    answer: "David Attenborough",
    imageUrl: "https://images.unsplash.com/photo-1615647112295-7f6355324a8c?w=400&h=400&fit=crop",
    name: "Sir David Attenborough",
    years: "1926-present",
    bio: "I am a broadcaster and natural historian who has brought the wonders of the natural world to television audiences for over six decades.",
    achievements: [
      "Created groundbreaking nature documentaries including 'Life on Earth'",
      "Controller of BBC Two and Director of Programming for BBC",
      "Knight of the Order of Merit",
      "Multiple BAFTA and Emmy awards",
      "Over 30 honorary degrees from British universities"
    ],
    funFacts: [
      "I studied geology and zoology at Cambridge",
      "I helped introduce colour television to Britain",
      "I've had numerous species named after me",
      "I hold the record for longest TV career"
    ],
    category: 'science',
    relatedPlaces: [
      {
        placeId: 'museum-1',
        description: "Visit the Museum of Zoology, where Attenborough studied specimens as a student. The museum houses many of the types of specimens featured in his early documentaries."
      }
    ]
  },
  {
    id: 'ramanujan',
    question: "I was a self-taught mathematical genius from India who collaborated with G.H. Hardy at Trinity College. Who am I?",
    answer: "Srinivasa Ramanujan",
    imageUrl: "https://images.unsplash.com/photo-1509228468518-180dd4864904?w=400&h=400&fit=crop",
    name: "Srinivasa Ramanujan",
    years: "1887-1920",
    bio: "I was a mathematical prodigy who made extraordinary contributions to mathematical analysis, number theory, infinite series, and continued fractions.",
    achievements: [
      "Fellow of the Royal Society",
      "Fellow of Trinity College, Cambridge",
      "Discovered thousands of innovative formulae",
      "Developed the theory of partition functions",
      "Made breakthrough contributions to infinite series"
    ],
    funFacts: [
      "I was largely self-taught in mathematics",
      "I believed my mathematical insights came from the goddess Namagiri",
      "The number 1729 is known as the Ramanujan-Hardy number",
      "I discovered many of my theorems in dreams"
    ],
    category: 'science',
    relatedPlaces: [
      {
        placeId: 'college-1',
        description: "Visit Trinity College where Ramanujan worked with G.H. Hardy. His remarkable story is commemorated in the college, and his mathematical notebooks are preserved in the Wren Library."
      }
    ]
  }
];