import React from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { X, Sparkles, MapPin, Calendar, ExternalLink } from 'lucide-react';
import { PLACES } from '../data/places';

interface OtherSydeOverlayProps {
  onClose: () => void;
}

export function OtherSydeOverlay({ onClose }: OtherSydeOverlayProps) {
  const otherSydePlaces = PLACES.filter(place => 
    place.categories?.includes('othersyde') || place.category === 'othersyde'
  );

  return (
    <OverlayWrapper>
      <div className="fixed inset-4 bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl shadow-xl overflow-hidden">
        {/* Header */}
        <div className="p-6 border-b border-white/10">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <Sparkles className="w-8 h-8 text-yellow-400" />
              <div>
                <h2 className="text-xl font-bold text-white">OtherSyde</h2>
                <p className="text-white/70">Discover Cambridge differently</p>
              </div>
            </div>
            <button 
              onClick={onClose}
              className="p-2 text-white hover:bg-white/10 rounded-lg"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="overflow-y-auto" style={{ height: 'calc(100% - 89px)' }}>
          <div className="p-6">
            {/* About Section */}
            <div className="bg-white/10 rounded-xl p-6 mb-8">
              <h3 className="text-lg font-semibold text-white mb-3">About OtherSyde</h3>
              <p className="text-white/80">
                OtherSyde creates unique experiences that showcase Cambridge's hidden gems, 
                alternative culture, and lesser-known stories. From walking tours to special 
                events, we help you discover the city's vibrant creative scene.
              </p>
            </div>

            {/* Tours & Events */}
            <div className="space-y-6">
              {otherSydePlaces.map(place => (
                <div key={place.id} className="bg-white/10 rounded-xl overflow-hidden">
                  <img 
                    src={place.imageUrl} 
                    alt={place.name}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-6">
                    <h3 className="text-xl font-bold text-white mb-2">{place.name}</h3>
                    <p className="text-white/80 mb-4">{place.description}</p>
                    
                    {place.contact && (
                      <div className="flex items-center gap-2 text-white/60 mb-2">
                        <MapPin className="w-4 h-4" />
                        <span>{place.contact.address}</span>
                      </div>
                    )}
                    
                    {place.openingHours?.notes && (
                      <div className="flex items-center gap-2 text-white/60 mb-4">
                        <Calendar className="w-4 h-4" />
                        <span>{place.openingHours.notes}</span>
                      </div>
                    )}

                    <div className="flex gap-3">
                      {place.websiteUrl && (
                        <a
                          href={place.websiteUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center gap-2 px-4 py-2 bg-white/10 hover:bg-white/20 
                            rounded-lg text-white transition-colors"
                        >
                          <ExternalLink className="w-4 h-4" />
                          Learn More
                        </a>
                      )}
                      {place.bookingUrl && (
                        <a
                          href={place.bookingUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 hover:bg-indigo-700 
                            rounded-lg text-white transition-colors"
                        >
                          <Calendar className="w-4 h-4" />
                          Book Now
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </OverlayWrapper>
  );
}