import React from 'react';
import { X, Gift, Heart, BookmarkPlus, ThumbsUp, Brain, Trophy } from 'lucide-react';
import { OverlayWrapper } from './OverlayWrapper';

interface InfoSheetProps {
  onClose: () => void;
  onSignUp: () => void;
}

export function InfoSheet({ onClose, onSignUp }: InfoSheetProps) {
  return (
    <OverlayWrapper>
      <div className="fixed inset-4 bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl shadow-xl z-50 flex flex-col">
        <div className="flex-none p-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <h2 className="text-xl font-bold text-white">Benefits of Joining</h2>
            </div>
            <button
              onClick={onClose}
              className="p-2 text-white hover:bg-white/10 rounded-lg"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {/* Sign Up CTA */}
          <button
            onClick={() => {
              onClose();
              onSignUp();
            }}
            className="mt-6 w-full py-4 px-6 bg-indigo-600 hover:bg-indigo-500 rounded-lg transition-colors shadow-lg"
          >
            <p className="text-white text-center font-medium">
              Join Today!
            </p>
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-6 pt-0">
          <div className="space-y-2">
            {/* Bonus Points Section */}
            <div className="bg-white/10 rounded-xl p-4 mb-6">
              <div className="flex items-center gap-3 mb-3">
                <Trophy className="w-6 h-6 text-yellow-400" />
                <h3 className="font-semibold text-white">Earn Bonus Points!</h3>
              </div>
              <p className="text-white/80 text-sm mb-3">
                Collect points as you explore Cambridge and unlock exclusive benefits:
              </p>
              <ul className="space-y-2 text-white/70 text-sm">
                <li className="flex items-center gap-2">
                  <span className="text-yellow-400">•</span>
                  <span>50 points for your first favourite</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-yellow-400">•</span>
                  <span>50 points for your first bookmark</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-yellow-400">•</span>
                  <span>100 points for your first review</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-yellow-400">•</span>
                  <span>25 points for each visited place</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-yellow-400">•</span>
                  <span>25 points per correct quiz answer</span>
                </li>
              </ul>
            </div>

            <button className="w-full px-4 py-3 text-white hover:bg-white/10 rounded-lg flex items-center gap-4">
              <Gift className="w-8 h-8 text-yellow-400" />
              <div className="text-left">
                <span className="block font-medium">Special Offers</span>
                <span className="text-sm text-white/70">Get exclusive deals and discounts</span>
              </div>
            </button>

            <button className="w-full px-4 py-3 text-white hover:bg-white/10 rounded-lg flex items-center gap-4">
              <Heart className="w-8 h-8 text-red-400" />
              <div className="text-left">
                <span className="block font-medium">Favourites</span>
                <span className="text-sm text-white/70">Save your favourite places</span>
              </div>
            </button>

            <button className="w-full px-4 py-3 text-white hover:bg-white/10 rounded-lg flex items-center gap-4">
              <BookmarkPlus className="w-8 h-8 text-purple-400" />
              <div className="text-left">
                <span className="block font-medium">Bookmarks</span>
                <span className="text-sm text-white/70">Create your Cambridge wishlist</span>
              </div>
            </button>

            <button className="w-full px-4 py-3 text-white hover:bg-white/10 rounded-lg flex items-center gap-4">
              <ThumbsUp className="w-8 h-8 text-blue-400" />
              <div className="text-left">
                <span className="block font-medium">Vote & Review</span>
                <span className="text-sm text-white/70">Share your experiences</span>
              </div>
            </button>

            <button className="w-full px-4 py-3 text-white hover:bg-white/10 rounded-lg flex items-center gap-4">
              <Brain className="w-8 h-8 text-green-400" />
              <div className="text-left">
                <span className="block font-medium">Cambridge Quiz</span>
                <span className="text-sm text-white/70">Test your knowledge</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </OverlayWrapper>
  );
}