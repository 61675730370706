import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Utensils, Landmark, TreePine, ShoppingBag, Calendar, Map, Gamepad2, Hotel, Beer, Music, Mic2, Hammer, Building2, GraduationCap, Coffee, Waves, Trophy, Dumbbell, Heart, Sparkles, School, Palette } from 'lucide-react';
import { categoryColors } from '../utils/categoryColors';
import type { Category } from '../types';

interface CategoryFilterProps {
  selected: string | null;
  onSelect: (category: string | null) => void;
  isSignedIn?: boolean;
  onSignUp?: () => void;
}

export const categories = [
  { id: 'top10' as Category, icon: Trophy, label: 'Top 10' },
  { id: 'eating' as Category, icon: Utensils, label: 'Food' },
  { id: 'colleges' as Category, icon: School, label: 'Colleges' },
  { id: 'pubs' as Category, icon: Beer, label: 'Pubs' },
  { id: 'history' as Category, icon: Landmark, label: 'History' },
  { id: 'outdoors' as Category, icon: TreePine, label: 'Outdoors' },
  { id: 'shopping' as Category, icon: ShoppingBag, label: 'Shopping' },
  { id: 'events' as Category, icon: Calendar, label: 'Events' },
  { id: 'tours' as Category, icon: Map, label: 'Tours' },
  { id: 'games' as Category, icon: Gamepad2, label: 'Games' },
  { id: 'stay' as Category, icon: Hotel, label: 'Stay' },
  { id: 'entertainment' as Category, icon: Music, label: 'Entertainment' },
  { id: 'talks' as Category, icon: Mic2, label: 'Talks' },
  { id: 'workshops' as Category, icon: Hammer, label: 'Workshops' },
  { id: 'museums' as Category, icon: Building2, label: 'Museums' },
  { id: 'classes' as Category, icon: GraduationCap, label: 'Classes' },
  { id: 'coffee' as Category, icon: Coffee, label: 'Coffee' },
  { id: 'cam' as Category, icon: Waves, label: 'The Cam' },
  { id: 'sport' as Category, icon: Trophy, label: 'Sport' },
  { id: 'fitness' as Category, icon: Dumbbell, label: 'Fitness' },
  { id: 'wellbeing' as Category, icon: Heart, label: 'Wellbeing' },
  { id: 'othersyde' as Category, icon: Sparkles, label: 'OtherSyde' },
  { id: 'art' as Category, icon: Palette, label: 'Art' }
] as const;

export function CategoryFilter({ selected, onSelect, isSignedIn, onSignUp }: CategoryFilterProps) {
  const { t } = useTranslation();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const selectedButtonRef = useRef<HTMLButtonElement>(null);

  // Scroll selected category into view
  useEffect(() => {
    if (selected && selectedButtonRef.current && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const button = selectedButtonRef.current;
      const containerLeft = container.scrollLeft;
      const containerRight = containerLeft + container.clientWidth;
      const buttonLeft = button.offsetLeft;
      const buttonRight = buttonLeft + button.clientWidth;

      if (buttonLeft < containerLeft) {
        container.scrollTo({ left: buttonLeft - 16, behavior: 'smooth' });
      } else if (buttonRight > containerRight) {
        container.scrollTo({ left: buttonRight - container.clientWidth + 16, behavior: 'smooth' });
      }
    }
  }, [selected]);

  return (
    <div className="sticky top-0 z-20 bg-[#2D1B69] shadow-lg">
      <div className="max-w-7xl mx-auto px-4">
        <div className="relative py-4 flex items-center">
          {/* Fixed "All" button */}
          <button
            onClick={() => onSelect(null)}
            className={`flex-none px-4 py-2 rounded-full text-sm font-medium transition-colors
              ${!selected 
                ? 'bg-white/20 text-white' 
                : 'text-white/70 hover:text-white hover:bg-white/10'}`}
          >
            All
          </button>

          {/* Gradient fade for scroll indication */}
          <div className="absolute left-16 top-0 bottom-0 w-8 bg-gradient-to-r from-[#2D1B69] to-transparent pointer-events-none z-10" />
          
          {/* Scrollable categories */}
          <div 
            ref={scrollContainerRef}
            className="flex-1 overflow-x-auto no-scrollbar ml-3"
            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            <div className="flex items-center gap-2">
              {categories.map(({ id, icon: Icon, label }) => {
                const colors = categoryColors[id];
                const isSelected = selected === id;

                return (
                  <button
                    key={id}
                    ref={isSelected ? selectedButtonRef : null}
                    onClick={() => onSelect(id)}
                    className={`flex-none flex items-center gap-2 px-4 py-2 rounded-full text-sm font-medium transition-colors
                      ${isSelected 
                        ? `${colors.bg} ${colors.text}` 
                        : 'bg-white/10 text-white hover:bg-white/20'}`}
                  >
                    <Icon className="w-4 h-4" />
                    {t(id)}
                  </button>
                );
              })}
            </div>
          </div>

          {/* Right gradient fade */}
          <div className="absolute right-0 top-0 bottom-0 w-8 bg-gradient-to-l from-[#2D1B69] to-transparent pointer-events-none" />
        </div>
      </div>
    </div>
  );
}