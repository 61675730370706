import React, { useState } from 'react';
import { X, Star } from 'lucide-react';
import { OverlayWrapper } from './OverlayWrapper';
import { useAppState } from '../lib/state';

interface ReviewFormProps {
  placeId: string;
  placeName: string;
  onClose: () => void;
}

export function ReviewForm({ placeId, placeName, onClose }: ReviewFormProps) {
  const { userProfile, reviews, addReview, updateReview, deleteReview } = useAppState();
  const existingReview = reviews.get(placeId);

  const [rating, setRating] = useState(existingReview?.rating || 0);
  const [comment, setComment] = useState(existingReview?.comment || '');
  const [isEditing, setIsEditing] = useState(!existingReview);

  // Get first name from full name
  const getFirstName = (fullName: string) => {
    return fullName.split(' ')[0];
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!userProfile) return;

    if (existingReview) {
      updateReview(placeId, rating, comment);
    } else {
      addReview(placeId, rating, comment);
    }

    setIsEditing(false);
  };

  if (!isEditing && existingReview) {
    return (
      <div className="bg-gradient-to-br from-indigo-50 to-purple-50 rounded-xl p-6 space-y-4">
        {/* Review Header */}
        <div className="flex items-start justify-between">
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              {/* User Avatar */}
              <div className="w-10 h-10 rounded-full bg-gradient-to-br from-indigo-400 to-purple-400 flex items-center justify-center text-white font-medium">
                {userProfile?.name.charAt(0).toUpperCase()}
              </div>
              <div>
                <h3 className="font-semibold text-gray-900">
                  {userProfile?.name ? getFirstName(userProfile.name) : 'Anonymous'}
                </h3>
                <div className="flex items-center gap-2 text-sm text-gray-500">
                  <span>{new Date(existingReview.date).toLocaleDateString()}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Rating Stars */}
        <div className="flex items-center gap-1">
          {[1, 2, 3, 4, 5].map((star) => (
            <Star
              key={star}
              className={`w-5 h-5 ${
                star <= existingReview.rating
                  ? 'text-yellow-400 fill-current'
                  : 'text-gray-300'
              }`}
            />
          ))}
        </div>

        {/* Review Content */}
        <div className="bg-white/50 rounded-lg p-4">
          <p className="text-gray-700 leading-relaxed">{existingReview.comment}</p>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="w-full">
      {/* Rating Section */}
      <div className="bg-gradient-to-br from-indigo-50 to-purple-50 rounded-xl p-6 mb-6">
        <label className="block text-lg font-medium text-gray-900 mb-3">
          Your Rating
        </label>
        <div className="flex items-center gap-3">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              type="button"
              onClick={() => setRating(star)}
              className="p-1 hover:scale-110 transition-transform"
            >
              <Star
                className={`w-5 h-5 ${
                  star <= rating
                    ? 'text-yellow-400 fill-current'
                    : 'text-gray-300'
                }`}
              />
            </button>
          ))}
        </div>
      </div>

      {/* Review Text Section */}
      <div className="mb-6">
        <label htmlFor="comment" className="block text-lg font-medium text-gray-900 mb-3">
          Your Review
        </label>
        <textarea
          id="comment"
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="w-full px-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 resize-none"
          placeholder="Share your experience with others..."
          required
        />
      </div>

      {/* Submit Button */}
      <div className="flex justify-center mb-6">
        <button
          type="submit"
          disabled={rating === 0 || !comment.trim()}
          className="w-full px-6 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl
            hover:from-indigo-500 hover:to-purple-500 disabled:opacity-50 disabled:cursor-not-allowed
            transition-all duration-200 font-medium"
        >
          Submit Review
        </button>
      </div>

      {/* Review Guidelines */}
      <div className="text-center text-sm text-gray-500 bg-gray-50 p-4 rounded-xl">
        <p className="mb-2 font-medium text-gray-600">Please read our review guidelines (below) before submitting your review</p>
        <p>Please be respectful and honest in your review. Share your genuine experience to help others make informed decisions. Personal attacks or inappropriate content will be removed.</p>
      </div>
    </form>
  );
}