import React from 'react';
import { OverlayWrapper } from './OverlayWrapper';
import { X, Gift, Calendar } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { PLACES } from '../data/places';
import { categoryColors } from '../utils/categoryColors';
import { categoryIcons } from '../utils/categoryIcons';

interface SpecialOffersOverlayProps {
  onClose: () => void;
  onPlaceClick: (id: string) => void;
}

export function SpecialOffersOverlay({ onClose, onPlaceClick }: SpecialOffersOverlayProps) {
  const { t } = useTranslation();
  
  // Filter places with special offers
  const placesWithOffers = PLACES.filter(place => place.specialOffer);

  return (
    <OverlayWrapper>
      <div className="fixed inset-4 bg-white rounded-xl shadow-xl overflow-hidden">
        {/* Header */}
        <div className="bg-gradient-to-r from-indigo-600 to-purple-600 p-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <Gift className="w-8 h-8 text-yellow-400" />
              <h2 className="text-xl font-bold text-white">Special Offers</h2>
            </div>
            <button
              onClick={onClose}
              className="p-2 text-white hover:bg-white/10 rounded-lg"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
          <p className="text-white/80 mt-2">Exclusive deals at Cambridge's best venues</p>
        </div>

        {/* List */}
        <div className="overflow-y-auto" style={{ height: 'calc(100% - 116px)' }}>
          {placesWithOffers.length > 0 ? (
            placesWithOffers.map(place => {
              const categories = place.categories || [place.category];
              
              return (
                <div
                  key={place.id}
                  className="border-b hover:bg-gray-50 cursor-pointer active:bg-gray-100 transition-colors"
                  onClick={() => onPlaceClick(place.id)}
                >
                  {/* Image */}
                  <div className="aspect-[2/1] w-full overflow-hidden">
                    <img
                      src={place.imageUrl}
                      alt={place.name}
                      className="w-full h-full object-cover"
                    />
                  </div>

                  {/* Content */}
                  <div className="p-4">
                    <div className="flex items-center justify-between mb-3">
                      <h3 className="font-semibold text-gray-900 text-lg">{place.name}</h3>
                      <div className="flex items-center gap-2 text-sm text-gray-500">
                        <Calendar className="w-4 h-4" />
                        <span>Valid until {new Date(place.specialOffer?.validUntil || '').toLocaleDateString()}</span>
                      </div>
                    </div>

                    {/* Category Labels */}
                    <div className="flex flex-wrap gap-2 mb-3">
                      {categories.map(cat => {
                        const Icon = categoryIcons[cat];
                        const colors = categoryColors[cat];
                        return (
                          <div 
                            key={cat}
                            className={`flex items-center gap-1 px-2 py-1 rounded-full text-sm ${colors.bg} ${colors.text}`}
                          >
                            <Icon className="w-4 h-4" />
                            <span>{cat}</span>
                          </div>
                        );
                      })}
                    </div>

                    {place.specialOffer && (
                      <div>
                        <p className="text-indigo-600 font-medium text-lg mb-3">
                          {place.specialOffer.description}
                        </p>
                        {place.specialOffer.code && (
                          <div className="bg-gray-50 px-3 py-2 rounded-lg inline-flex items-center gap-2">
                            <span className="text-sm text-gray-600">Use code:</span>
                            <code className="font-mono text-indigo-600 font-medium">
                              {place.specialOffer.code}
                            </code>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="p-8 text-center">
              <Gift className="w-12 h-12 mx-auto mb-4 text-gray-400" />
              <p>No special offers available at the moment.</p>
              <p className="text-sm mt-2">Check back soon for new deals!</p>
            </div>
          )}
        </div>
      </div>
    </OverlayWrapper>
  );
}