import React from 'react';

interface OverlayWrapperProps {
  children: React.ReactNode;
}

export function OverlayWrapper({ children }: OverlayWrapperProps) {
  return (
    <div className="fixed inset-0 z-[100]">
      {/* Backdrop with blur */}
      <div 
        className="fixed inset-0 bg-black/60"
        style={{ 
          backdropFilter: 'blur(8px)',
          WebkitBackdropFilter: 'blur(8px)'
        }}
      />
      
      {/* Content */}
      <div className="relative min-h-full flex items-center justify-center p-4">
        <div className="w-full max-h-[calc(100vh-32px)] bg-gradient-to-br from-indigo-900 via-purple-900 to-[#1A1F2B] rounded-2xl shadow-2xl overflow-hidden">
          {children}
        </div>
      </div>
    </div>
  );
}